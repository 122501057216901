import { Box } from '@mui/material'
import { FC } from 'react'
import { Goods, GoodsProps } from './Goods'

interface GoodsListProps {
  items?: GoodsProps[]
}
export const GoodsList: FC<GoodsListProps> = ({ items }) => {
  items = [
    {
      imgUrl: require('../../assets/goods/1.png'),
      title: 'Nintendo Switch Pro Controller',
      desc: 'Description：Controller black or white * 1',
      score: '???',
      state: '',
      date: 'Date: TBD',
    },
    {
      imgUrl: require('../../assets/goods/2.png'),
      title: 'Nintendo Switch Console Super Mario',
      desc: 'Description：Switch Console * 1',
      score: '???',
      state: '',
      date: 'Date: TBD',
    },
    {
      imgUrl: require('../../assets/goods/3.png'),
      title: 'PlayStation 5 Consoles',
      desc: 'Description：PlayStation 5 Consoles * 1',
      score: '???',
      state: '',
      date: 'Date: TBD',
    },
    {
      imgUrl: require('../../assets/goods/4.png'),
      title: 'SONY PlayStation VR2',
      desc: 'Description：PlayStation VR2 * 1',
      score: '???',
      state: '',
      date: 'Date: TBD',
    },
    {
      imgUrl: require('../../assets/goods/5.png'),
      title: 'IPHONE 14 PRO',
      desc: 'Description：IPHONE 14 PRO * 1',
      score: '???',
      state: '',
      date: 'Date: TBD',
    },
  ]
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '22.8125rem',
          mb: '2.5rem',
          borderRadius: '0.625rem',
          height: '2.625rem',
          lineHeight: '2.625rem',
          overflow: 'hidden',
          fontSize: '0.875rem',
          textAlign: 'center',
          border: '2px solid #6c5461',
        }}
      >
        <Box sx={{ flex: '204', color: '#fff', backgroundColor: '#6c5461' }}>Price hige to Low</Box>
        <Box sx={{ flex: '161', color: '#6c5461', backgroundColor: '#fff' }}>Newly Listed</Box>
      </Box>
      <Box sx={{ display: 'grid', gap: '5.5rem', width: '100%', pb: '3rem' }}>
        {items.map((item, index) => (
          <Goods key={index} {...item} />
        ))}
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 50,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,.01)',
            backdropFilter: 'blur(2px)',
          }}
        ></div>
        <img
          src={require('../../assets/goods/x.svg').default}
          alt=""
          style={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
        />
      </Box>
    </Box>
  )
}
