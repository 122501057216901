import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { connectors } from '../../web3/connectors'

const useConnectWallect = () => {
  const { active, activate, account, chainId } = useWeb3React()
  useEffect(() => {
    const walletName = window.localStorage.getItem('provider')
    if (!active && walletName) {
      if (walletName === 'MetaMask') activate(connectors.injected)
      if (walletName === 'WalletConnect') activate(connectors.walletConnect)
    }
  }, [activate, active])

  return {
    active,
    activate,
    account,
    chainId,
  }
}

export default useConnectWallect
