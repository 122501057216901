import DoneIcon from '@mui/icons-material/Done'
import { Box } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

interface INftCard {
  image: any
  title: string
  score?: string
  isActive?: boolean
  locked?: boolean
  /**
   * 是否可选中
   */
  checkable?: boolean
  handleClick?: () => void
}

const CardWrap = styled.div<{ isActive?: boolean }>`
  position: relative;
  padding: 1.25em 0.75em;
  width: 100%;
  background: ${(props: any) => (props.isActive ? '#FBF9F9' : '#ebe7e4')};
  border: ${(props: any) => (props.isActive ? '2px solid #6C5461' : 'none')};
  box-shadow: ${(props: any) => (props.isActive ? '4px 4px 4px rgba(0, 0, 0, 0.2)' : 'none')};
  border-radius: 0.625em;
  overflow: hidden;
  .nft-image-wrap {
    width: 100%;
    position: relative;

    .nft-image-active-icon {
      position: absolute;
      top: -0.25em;
      right: -0.25em;
      background: #6c5461;
      color: #fff;
      z-index: 1;
      border-radius: 50%;
      width: 0.9em;
      height: 0.9em;
    }
  }
  .nft-image {
    width: 100%;
    border-radius: 0.625em;
    position: relative;
  }

  .nft-title {
    text-align: center;
    color: #6c5461;
    margin-top: 0.5em;
    font-size: 1.2em;
    font-weight: bolder;
  }
  .nft-score {
    color: rgba(220, 28, 75, 0.8);
    margin-top: 0.75em;
    text-align: center;
  }

  .active {
    background: #fbf9f9;
    border: 2px solid #6c5461;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  }
  .nft-active {
  }
  @media (max-width: 576px) {
    padding: 1.2em 1.5em;
    margin: 0 auto;
    width: 80%;
    border-radius: 5%;
    .nft-title {
      font-size: 1.2em;
    }
    .nft-image-wrap {
      width: 100%;
      position: relative;

      .nft-image-active-icon {
        position: absolute;
        top: -0.5em;
        right: -0.5em;
        background: #6c5461;
        color: #fff;
        z-index: 1;
        border-radius: 50%;
        width: 1em;
        height: 1em;
      }
    }
  }
`

const NftCard: React.FC<INftCard> = (props) => {
  const { isActive, handleClick, locked, checkable = true } = props
  return (
    <CardWrap isActive={isActive} onClick={() => checkable && handleClick?.()}>
      <div className={`nft-image-wrap`}>
        {isActive && <DoneIcon className="nft-image-active-icon" />}
        <img className="nft-image" src={props.image} alt="" />
      </div>
      <div className="nft-title">{props.title}</div>
      {props.score && <div className="nft-score">{props.score}</div>}
      {locked && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 99,
            backgroundColor: 'rgba(0,0,0,.2)',
            fontSize: '16px',
            pt: 3,
            textAlign: 'center',
            color: 'rgba(255, 255, 255, 0.8);',
          }}
        >
          LOCKED
        </Box>
      )}
    </CardWrap>
  )
}

export default NftCard
