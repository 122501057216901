import styled from 'styled-components'
import catPng from '../../assets/cat.png'

import catBg from '../../assets/catBg.png'
const ShopListInfoWrap = styled.div`
  background: #ebe7e4;
  border-radius: 1em;
  width: 73%;
  font-size: 1rem;
  background-size: contain;
  background-position: 0 0;

  // xs
  @media (max-width: 576px) {
    width: auto;
    width: 100%;
    max-width: 576px;
    color: #6c5461;
    border-radius: 0em;
    line-height: 1.5;

    .image_wrap {
      background-image: url(${catBg});
      background-repeat: no-repeat;
      background-size: inherit;
    }
    .iamge_cat {
      width: 30.7%;
      object-fit: contain;
      background-image: url(${catPng});
      background-repeat: no-repeat;
      background-size: inherit;
    }
    .shop_content_text_section {
      padding: 1em;
      font-size: 2em;
      .shop_content_title {
        text-align: left;
        padding-bottom: 0.1em;
        font-size: 0.7em;
      }
      .shop_content_title_sub {
        text-align: left;
        opacity: 0.5;
        padding-bottom: 2em;
        font-size: 0.2em;
      }

      .shop_content_bottom_wrap {
        background: #fbf9f9;
        padding: 0.1em;
        border-radius: 0.825em;
        text-align: left;
        font-size: 1em;
      }
    }
    .shop_content_bottom {
      padding: 0.6em;
      .shop_content_bottom_title {
        font-size: 0.8em;
      }
      .shop_content_bottom_title_sub {
        opacity: 0.5;
        font-size: 0.6em;
      }
      .shop_content_counter {
        font-size: 1.6em;
        span {
          font-size: 1.5em;
        }
      }
      .shop_content_coming {
        font-size: 0.6em;
        /* opacity: 0.5; */
        line-height: 1.2;
      }
    }
  }

  @media (min-width: 577px) {
    background-image: url(${catBg});
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #6c5461;
    text-align: left;
    .iamge_cat {
      width: 30.7%;
    }
    .shop_exchange_content {
      padding: 1.8em 1.8em 1.5em 0;
      width: 64.3%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .shop_content_title_section {
        display: flex;
        flex-direction: column;
        .shop_content_title {
          line-height: 1.2;
          font-size: 1.75em;
        }
        .shop_content_title_sub {
          font-size: 1.25em;
          opacity: 0.5;
          line-height: 1.5;
        }
      }

      .shop_content_bottom_wrap {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        line-height: 1.2;

        margin-top: 1em;
        .shop_content_bottom {
          display: flex;
          flex-direction: column;
          padding: 1em;
          background-color: #fbf9f9;
          border-radius: 0.825em;
          text-align: right;

          .shop_content_bottom_title {
            font-size: 1.25em;
            line-height: 1.5;
          }
          .shop_content_bottom_title_sub {
            font-size: 1em;
            line-height: 1.5;
            opacity: 0.5;
          }
          .shop_content_counter {
            font-size: 1.5em;
            span {
              line-height: 1;
              font-size: 3em;
            }
          }
          .shop_content_coming {
            font-size: 1em;
            opacity: 0.5;
            line-height: 1.2;
          }
        }
      }
    }
  }
`

export default ShopListInfoWrap
