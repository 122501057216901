import styled from "styled-components";

export const SkeletonStyled = styled.div<{ liveWidth?: number }>`
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3C7BCA;
  z-index: 20;
  .progress_logo {
    width: 6em;
    position: absolute;
    top: 2em;
    left: 2em;
  }

  .skeleton_container {
    width: 60%;
    height: 30em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .loading-pic {
      width: 25em;
      height: 25em;
      @media(max-Width: 576){
        width: 10em;
        height: 10em;
      }
    }
    .loading-box {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      height: 2em;
      margin-top: 2em;
      .loading-blank {
        width: 95%;
        background-color: #3C7BCA;
        opacity: 0.15;
      }
      .loading-blank-inner {
          width: ${(props: any) => (props.liveWidth - 5) || 10}%;
          height: 100%;
          background-color: #cbd4e9;
          position: absolute;
          border-radius: 2em;
          transition: all .6s;
        }
      .progress {
        width: 5%;
        text-align: center;
        vertical-align: middle;
        font-family: 'Coco';
        color: #ffffff;
        font-size: 2em;
      }
    }
    .loading-content {
      p {
        font-family: 'Coco';
        color: #ffffff;
        font-size: 3em;
      }
      margin-top: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
  }
`