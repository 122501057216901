import { styled } from '@mui/material/styles'

import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { FC } from 'react'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(108, 84, 97, 0.31)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: '#6c5461',
  },
}))

export interface CustomizedProgressBarsProps extends LinearProgressProps {}
export const CustomizedProgressBars: FC<CustomizedProgressBarsProps> = (props) => {
  return <BorderLinearProgress variant="determinate" {...props} />
}
