import { ContractInterface } from 'ethers'

export const SHOP_ADDRESS_Collection = {
  WH_NFT: {
    contract: '0xE86c0d5F3c9Cad0dA9500b1E914d6B7b875A2b9C',
    abi: require('./whNft.json') as ContractInterface,
  },
  WH_STAKE: {
    contract: '0x526dd2E56035B08F1bd8032F28dce26d3fc2c60C', // 0xb8f9D42bAa9C24ed44362147063a8e63EDEba106 - logic
    abi: require('./whHolter.json') as ContractInterface, // from 0xb8f9D42bAa9C24ed44362147063a8e63EDEba106 - logic
  },
  WH_PROXY: {
    contract: '0x526dd2E56035B08F1bd8032F28dce26d3fc2c60C', // storage
    abi: require('./whProxy.json'),
  }
}