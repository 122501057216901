import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import React, { useCallback, useEffect, useState } from 'react'
import { SHOP_ADDRESS_Collection } from '../contract/contractAddress'
import { StakedToken } from '../types'
import BigNumber from 'bignumber.js'

export const useHook = () => {
  const { library, account } = useWeb3React()
  const [rankingList, setRankingList] = useState<{ address: string; score: number }[]>([])
  const [bonus, setBonus] = useState<number>(0)
  const [totalClaimBonus, setTotalClaimBonus] = useState<number>(0)
  const [totalStakedCount, setTotalStakedCount] = useState<number>(0)
  const [userStakedTokens, setUserStakedTokens] = useState<StakedToken[]>([])

  const stakeContract = new ethers.Contract(
    SHOP_ADDRESS_Collection.WH_STAKE.contract,
    SHOP_ADDRESS_Collection.WH_STAKE.abi,
  )

  useEffect(() => {
    if (!library) return
    getRankingList()
    getUserInfo()
  }, [library])

  const getRankingList = useCallback(async () => {
    if (!library) return
    try {
      const provider = new ethers.providers.Web3Provider(library.provider)
      const stakeContractWithSigner = stakeContract.connect(provider)

      const list = await stakeContractWithSigner.getUsers()
      let ranks = await stakeContractWithSigner.getRank()

      const rankingList = list
        .map((user: string, index: number) => {
          return {
            address: user,
            score: ethers.utils.formatEther(ranks[index]).toString(),
          }
        })
        .sort((a: any, b: any) => b.score - a.score)

      setRankingList(rankingList)
    } catch (error: any) {
      console.error(error.message)
    }
  }, [library])

  const getUserInfo = useCallback(async () => {
    if (!library) return
    try {
      const provider = new ethers.providers.Web3Provider(library.provider)
      const stakeContractWithSigner = stakeContract.connect(provider)

      // 查询钱包下所有已获得积分
      const bonus = await stakeContractWithSigner.bonus(account)
      // setBonus(bonus.toNumber())
      setBonus(Number(ethers.utils.formatEther(bonus).toString()))

      // 查询用户总积分包含尚未领取积分
      const totalClaimBonus = await stakeContractWithSigner.getUserBonus(account)
      // setTotalClaimBonus(totalClaimBonus.toNumber())
      setTotalClaimBonus(Number(ethers.utils.formatEther(totalClaimBonus).toString()))

      // 用户质押的所有NFT tokenID
      const userTokens = await stakeContractWithSigner.getUserTokens(account)
      const userStakedTokens = await Promise.all(
        userTokens.map(async (item: ethers.BigNumber) => {
          try {
            const tokenId = item.toNumber()

            const tokenStakeInfo = await stakeContractWithSigner.tokenStakeInfo(tokenId)

            return {
              tokenId: tokenId,
              title: `Genesis #${tokenId}`,
              image: `https://wanghaivillage.s3.amazonaws.com/product/image/${tokenId}.png`,
              level: tokenStakeInfo.level.toNumber(),
              startTimestamp: tokenStakeInfo.startTimestamp.toNumber(),
              endTimestamp: tokenStakeInfo.endTimestamp.toNumber(),
              rewardPerSecond: new BigNumber(tokenStakeInfo.rewardPerSecond.toString()).div(1e18).dp(2).toNumber(),
            }
          } catch (error: any) {
            console.error(error.message)
            return null
          }
        }),
      )

      setUserStakedTokens(userStakedTokens.filter((item) => item))

      // 池子质押总数
      const totalStakedLevel1Count = await stakeContractWithSigner.totalIdSupply(1)
      const totalStakedLevel2Count = await stakeContractWithSigner.totalIdSupply(2)
      const totalStakedLevel3Count = await stakeContractWithSigner.totalIdSupply(3)

      setTotalStakedCount(
        totalStakedLevel1Count.toNumber() +
          totalStakedLevel2Count.toNumber() +
          totalStakedLevel3Count.toNumber(),
      )
      // setTotalStakedCount(totalStakedCount.toNumber())
    } catch (error: any) {
      console.error(error.message)
    }
  }, [library])

  const unStakeAndClaim = useCallback(
    async (tokenId: number) => {
      if (!library) return
      const provider = new ethers.providers.Web3Provider(library.provider)
      const stakeContractWithSigner = stakeContract.connect(provider).connect(provider.getSigner())

      return new Promise(async (resovle, reject) => {
        try {
          console.log('tokenId===', tokenId)
          // await stakeContractWithSigner.estimateGas.unStake(tokenId)
          const unStakeTransaction = await stakeContractWithSigner.unStake(tokenId)
          console.log('unStakeTransaction hash', unStakeTransaction.hash)
          await unStakeTransaction.wait()
          resovle(true)
        } catch (error: any) {
          console.error(error.message)
          reject()
        }
      })
    },
    [library],
  )

  return {
    rankingList,
    bonus,
    totalClaimBonus,
    userStakedTokens,
    totalStakedCount,
    unStakeAndClaim,
  }
}
