import styled from "styled-components";

export const ArtStyled = styled.div`
    position: relative;
    width: 100%;
    
    .art_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 13%;
        padding-bottom: 5%;
        margin-bottom: 5%;
        position: relative;

        img.roadmap{
            width: 85%;
        }
        
        img.arrow{
            width: 4.4em;
            position: absolute;
            bottom: 0%;
            left: 50%;
            margin-left: -2.2em;

            animation: arrowDance .5s;
            -webkit-animation: arrowDance .5s
        }
    }

    @keyframes arrowDance{
        from {bottom: 0%}
        to {bottom: -6%}
    }
`