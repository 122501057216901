import { Popover } from '@mui/material'
import styled from 'styled-components'
import walletBgPng from './assets/wallet_bg.png'

export const WalletConnectStyles = styled.div`
  position: relative;
  width: 100%;
  button {
    background-color: transparent;
    border: none;
  }

  .connect_btn {
    width: 100%;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
      transition: all 0.3s;
    }

    &:active {
      transform: scale(1);
    }

    // @media (max-width: 576px) {
    //   width: 50%;
    // }
  }

  .connected_box {
    display: flex;
    align-items: center;
    background: #6c5461;
    height: 2.875rem;
    padding: 0.75rem 1rem;
    box-shadow: 4px 4px 0px #333333;
    border: 2px solid #333333;
    p {
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }

    img {
      width: 1.2em;
      margin-left: 0.5em;
      cursor: pointer;
    }
  }
`

export const CustomPopover = styled(Popover)`
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    margin-top: -0.2em;
    margin-left: -10em;
    width: 300px;

    @media (max-width: 576px) {
      margin-left: -2em;
    }

    .connect_ul {
      background-image: url(${walletBgPng});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;

      right: 50%;
      display: flex;
      flex-direction: column;
      padding: 2em 1em;
      box-sizing: border-box;

      li {
        width: 100%;
        /* box-sizing: border-box; */
        display: flex;
        align-items: center;
        padding-left: 30%;
        padding-right: 10%;
        margin-bottom: 1em;
        cursor: pointer;

        &:first-child {
          margin-top: 0.5em;
        }

        div {
          width: 100%;
          background-color: #fef9cb;
          display: flex;
          align-items: center;
          color: #000;
          height: 2.5em;
          margin-bottom: 5%;
          box-shadow: 0.3em 0.3em 0em #000;

          img {
            width: 1em;
            height: 1em;
            margin-right: 0.3em;
            margin-left: 0.3em;
          }

          p {
            font-family: Coco;
            font-size: 0.6em;

            &.walletConnect {
              font-size: 0.4em;
            }
          }

          &:hover {
            transform: scale(1.05);
            transition: all 0.3s;
          }
        }
      }
    }
  }
`
