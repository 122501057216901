import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { uid } from 'react-uid'

import woodenMobileBg from '../../assets/woodenMobileBg.png'
import woodenFrameBg from '../../assets/woodenframe.png'
import WoodenFrameWrap from './style'
import StakeModal, { useStakeModal } from '../StakeModal'
import ClaimModal from '../ClaimModal'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import { useHook } from '../useHook'
import { useShopContract } from '../../contractUtils'

// fetch - score

interface IWoodenFram {
  title: string
  subtitle: string
  score: string
  type: string
}

const WoodenFrame: React.FC<{ size: string }> = (props) => {
  const { size } = props
  const [frameArray, setFrameArray] = useState<Array<any>>([])

  const { open, handleOpen, ...stakeProps } = useStakeModal()
  const { bonus, totalClaimBonus, userStakedTokens } = useHook()
  const { getUserNftsInfo } = useShopContract()
  const [userNfts, setUserNfts] = useState<any[]>([])

  const { open: claimState, handleOpen: handleOpenClaim, ...claimProps } = useStakeModal()

  const { account, chainId } = useWeb3React()

  const handleOpenModal = (type: string) => {
    if (!account)
      return toast.warning('Please connect your wallet', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      })

    if (chainId !== 1 && chainId !== 5)
      return toast.warning('Please switch to the ethereum network', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      })

    // check connected wallet first
    if (type === 'stake') {
      handleOpen()
      return
    }

    if (type === 'claim') {
      handleOpenClaim()
    }
  }

  useEffect(() => {
    const woodenFrameArray = [
      {
        title: 'YOUR STAKE',
        subtitle: 'to earn score',
        score: `${userStakedTokens.length} / ${userNfts.length + userStakedTokens.length} NFT`,
        type: 'stake',
      },
      {
        title: 'SCORE EARNED',
        subtitle: 'Can be claimed',
        // score: '0',
        score: totalClaimBonus - bonus,
        type: 'claim',
      },
      {
        title: 'SCORE in wallet',
        subtitle: 'Wanghai Score',
        // score: '0',
        score: bonus,
        type: 'wallet',
      },
    ]

    setFrameArray(woodenFrameArray)
  }, [totalClaimBonus, bonus, userStakedTokens, userNfts])

  useEffect(() => {
    ;(async () => {
      const userNfts = await getUserNftsInfo()
      setUserNfts(userNfts)
    })()
  }, [getUserNftsInfo])

  const WoodenFrameCellPc = ({ frame }: { frame: IWoodenFram }) => {
    return (
      <div className="wooden_frame_cell_content">
        <div className="wooden_frame_text_content">
          <div>
            <div>{frame.title}</div>
            <div className="wooden_frame_cell_subtitle">{frame.subtitle}</div>
          </div>
          {frame.type !== 'wallet' && (
            <Button className={`wooden_frame_cell_btn`} onClick={() => handleOpenModal(frame.type)}>
              {frame.type === 'stake' ? 'stake' : 'claim'}
            </Button>
          )}
        </div>
        <div className="wooden_frame_cell_score">
          {/* {`${frame.score?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`} */}
          {`${frame.score}`}
        </div>
      </div>
    )
  }

  return (
    <WoodenFrameWrap>
      {frameArray.map((frame) => {
        return (
          <div key={uid(frame)} className="wooden_frame_cell_wrap">
            <img
              className="wooden_frame_cell_bg"
              src={size === 'xs' ? woodenMobileBg : woodenFrameBg}
              alt=""
            />
            {size !== 'xs' ? ( // pc
              <WoodenFrameCellPc key={uid(frame)} frame={frame} />
            ) : (
              // mobile
              <div className="wooden_frame_cell_content">
                <div>{frame.title}</div>
                <div className="wooden_frame_cell_subtitle">{frame.subtitle}</div>
                <div className="wooden_frame_cell_score">
                  {`${frame.score}`}
                  {/* 千分位正则 */}
                </div>
                {frame.type !== 'wallet' && (
                  <Button
                    className={`wooden_frame_cell_btn`}
                    sx={{ height: '48px' }}
                    onClick={() => handleOpenModal(frame.type)}
                  >
                    {frame.type === 'stake' ? 'stake' : 'claim'}
                  </Button>
                )}
              </div>
            )}
          </div>
        )
      })}
      {open && <StakeModal {...stakeProps} open={open} />}
      {claimState && <ClaimModal open={claimState} {...claimProps} />}
    </WoodenFrameWrap>
  )
}

export default WoodenFrame
