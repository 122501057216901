import { SkeletonStyled } from "./style";
import React from 'react';
import magicBookGif from '../../page/About/assets/magic_book.gif'
import logo from '../Header/assets/logo.png'

interface ISkeleton {
  visible: boolean;
  progress: number
}

export const Skeleton: React.FC<ISkeleton> = (props: ISkeleton) => {
  const { visible = false, progress = 0 } = props;
  return (
    visible ? 
    <SkeletonStyled liveWidth={progress}>
      <img src={logo} className="progress_logo" alt="..."/>
      <div className="skeleton_container">
        <img className="loading-pic" src={magicBookGif} alt="..."/>
        <div className="loading-box">
          <div className="loading-blank"></div>
          <div className="loading-blank-inner"></div>
          <div className="progress">{ progress }%</div>
        </div>
        <div className="loading-content">
          <p style={{ fontFamily: 'Coco', fontSize: '3em', color: '#FFFFFF' }}>LOADING...</p>
        </div>
      </div>
    </SkeletonStyled> : null
  )
}