import styled from "styled-components";

export const AboutStyled = styled.div`
    position: relative;
    width: 100%;

    .about_wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20%;
        margin-bottom: 5%;

        .about_left{
            width: 39%;
            display: flex;
            justify-content: center;

            img{
                width: 70%;
            }
        }

        .about_right{
            width: 59%;
            height: auto;
            display: flex;
            justify-content: center;

            .about_contont{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h2{
                    font-family: 'Untitled';
                    font-size: 8em;
                    color: #FFFCE2;
                    text-shadow: 4px 4px 13px #1659AC;
                    margin-bottom: 0.15em;
                }

                h3{
                    font-family: 'Untitled';
                    font-size: 4.8em;
                    text-shadow: 4px 4px 13px #1659AC;
                    color: #FFFCE2;
                    margin-bottom: 0.8em;
                }

                p.about_subTitle{
                    font-family: 'Coco';
                    font-size: 2.4em;
                    color: #FFFFFF;
                    -webkit-text-stroke: 0.1em #000000;
                    margin-top: 2em;
                    margin-bottom: 2em;
                }

                p.about_text{
                    font-family: 'Coco';
                    font-size: 1.125em;
                    line-height: 1.25em;
                    text-shadow: 1px 1px 9px #03316A;
                    color: #fff;
                    display: flex;
                    width: 75%;
                }
            }

            img{
                position: absolute;
                width: 35%;
                right: 5%;
                top: -15%;
            }
        }
    }
   
   
`