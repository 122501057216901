import styled from "styled-components";

export const FaqStyle = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10em;
  padding-bottom: 20em;

  .faq_wrapper {
    width: 74%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* margin-bottom: 100px; */
    padding-bottom: 5em;

    .faq-head {
      font-size: 8em;
      font-weight: 400;
      font-family: 'Untitled';
      color: #FFFCE2;
      text-shadow: 4px 4px 13px #1659AC;
    }

    ul{
      width: 100%;
      margin-top: 3em;

      li{
        width: 100%;
        margin-left: -16px;
        margin-bottom: 1em;
        /* height: 5em; */

        .faq_box{
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
          padding: 0;

          color: #fff;
          font-family: 'Coco';
          font-size: 1.9em;

          .faq_title{
            font-family: 'Coco';
            font-size: 0.8em;
          }

          .faq_anwser{
            font-family: 'Coco';
            font-size: 0.8em;
            color: #A07500;
            text-shadow: 0 0 .8em #fff;
          }
        }
      }
    }
  }
`
