import { toast } from "react-toastify"

export const compareAddress = (address1: string, address2: string) => {
  return String(address1).toLowerCase() === String(address2).toLocaleLowerCase()
}

export const omitAddress = (address: string) => {
  if (!address) return 'Null Address'
  const start = address.slice(0, 6)
  const end = address.slice(-4)

  return `${start}...${end}`
}

export const printError = (error: any) => {
  const startIndex = error?.message.indexOf(`reason="execution reverted:`) + 7
  const endIndex = error?.message.indexOf(`method="estimateGas"`) - 1
  const errorMsg = error?.message.slice(startIndex, endIndex) || 'Unknown error'
  toast.error(errorMsg, {
    autoClose: 2000,
    position: toast.POSITION.TOP_CENTER,
  })
}