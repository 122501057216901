import { TeamStyled } from './style'
import teamTitleBgPng from './assets/team_title_bg.png'
import team1Png from './assets/p1.jpg'
import team2Png from './assets/p2.jpg'
import team3Png from './assets/p3.png'
import team4Png from './assets/p4.jpg'
import arrowRightPng from './assets/arrow_right.png'
import { useState } from 'react'
import { uid } from 'react-uid'

const teamList = [{
    img: team1Png,
    name: 'Donnie',
    desc: "Founder, responsible for the artistic management and branding of the entire project.",
    twitter: 'https://twitter.com/Donnie_eth'
}, {
    img: team2Png,
    name: 'Orlando Nam',
    desc: "Is the head of a large apparel design and production company in China(Hanghai Knitting) and has been involved in the design of several fashion brands."
}, {
    img: team3Png,
    name: 'Homie_Xu',
    desc: "Full Stack Engineer, Developer",
    twitter: 'https://twitter.com/homie_xu'
}, {
    img: team4Png,
    name: 'Jane',
    desc: "Originally from New York City and studying at the School of Visual Arts in NYC. entered the cryptocurrency art scene in 2020 and then focused on the art and design portion of NFT and fully committed to the NFT industry. She is responsible for the overall narrative and art style of NFT.",
    twitter: 'https://twitter.com/UniqueJane_eth'
}]

export const Team = () => {
    const [currentActiveIndex, setCurrentActiveIndex] = useState(0)


    return (
        <TeamStyled id='TEAM'>
            <div className='team_wrapper'>
                <div className="team_title">
                    <img className='team_title_bg' src={teamTitleBgPng} alt="" />
                    <h1>team<br />members</h1>
                </div>
                <p className="sub_title">Wanghai Village is an entrepreneurial project led by donnie and co-founded by Jane.</p>
                <ul className='team_contont'>
                    {teamList.map((item, index) => {
                        return <li key={uid(item)}>
                            <img src={item.img} alt="" />
                            <p className='user_name'>{item.name}</p>
                            <div className='main'>
                                <div
                                    className='learn_btn'
                                    onClick={() => {
                                        setCurrentActiveIndex(index + 1)
                                    }}
                                >MORE INFO</div>
                                <div>
                                    <img
                                        src={arrowRightPng}
                                        onClick={() => {
                                            item.twitter && window.open(item.twitter)
                                        }}
                                        alt=""
                                    />
                                </div>
                            </div>

                            {currentActiveIndex === index + 1 && <div
                                className='desc_mask'
                                onMouseLeave={() => {
                                    setCurrentActiveIndex(0)
                                }}
                            >
                                <p>{item.desc}</p>
                            </div>}
                        </li>
                    })}
                </ul>
                {/* <div className='team-logo'>
                    <img src={teamVPng} alt="" />
                </div>
                <div className='team-title'>
                    <div className='team-title-head'>
                        team <br /> members
                    </div>
                    <div className='team-title-content'>
                        Wanghai Village is an entrepreneurial project led by donnie and co-founded by Jane.
                    </div>
                    <img src={teamPacket} className='team-packet'/>
                </div>
                <div className='team-card'>
                    {
                        teamList.map((item, index) => {
                            return <TeamCard key={index} img={item.img} name={item.name} desc={item.desc}/>
                        })
                    }
                </div> */}
            </div>

        </TeamStyled>
    )
}
