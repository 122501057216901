import styled from 'styled-components';

export const RulesStyled = styled.div`
    position: relative;
    width: 100%;

    .rules_wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15%;
        margin-bottom: 5%;
        padding-left: 12%;

        .rule_bg{
            position: absolute;
            width: 60%;
            right: -10%;
        }

        .rules_contont{
            display: flex;
            flex-direction: column;

            h2{
                font-family: 'Untitled';
                font-size: 8em;
                color: #FFFCE2;
                text-shadow: 4px 4px 13px #1659AC;
                margin-bottom: 0.15em;
                margin-bottom: 0.2em;
            }

            h3{
                font-family: 'Untitled';
                font-size: 6em;
                color: #FFFCE2;
                text-shadow: 4px 4px 13px #1659AC;
                margin-bottom: 0.15em;
                margin-bottom: 0.2em;
            }

            p.rules_text{
                font-family: 'Coco';
                font-size: 1.125em;
                line-height: 1.5em;
                text-shadow: 1px 1px 9px #03316A;
                color: #fff;
                display: flex;
                width: 45%;
                margin-top: 2em;
            }

            img{
                width: 20%;
                margin-top: 3.5em;
            }
        }

        
    }
`