import React from 'react'

import ShopListInfoWrap from './style'

import catPng from '../../assets/cat.png'
import { useHook } from '../useHook'

const ShopListInfo: React.FC<{ size: string }> = (props) => {
  const { size } = props
  const isMobile = size === 'xs'
  const { totalStakedCount } = useHook()

  if (isMobile) {
    return (
      <ShopListInfoWrap>
        <div className="image_wrap">
          <img className="iamge_cat" src={catPng} alt="" />
        </div>
        <div className="shop_content_text_section">
          <div className="shop_content_title">Exchange SHOP list</div>
          <div className="shop_content_title_sub">Many Goods can be exchanged now</div>

          <div className="shop_content_bottom_wrap" style={{ width: '100%' }}>
            <div className="shop_content_bottom">
              <div className="shop_content_bottom_title">Live Good Amount / Total</div>
              <div className="shop_content_bottom_title_sub">Continuously updated</div>
              <div className="shop_content_counter">
                <span>{totalStakedCount}</span>/617
              </div>
              <div className="shop_content_coming">* 3 Upcoming *</div>
            </div>
          </div>
        </div>
      </ShopListInfoWrap>
    )
  }
  return (
    <ShopListInfoWrap>
      <img className="iamge_cat" src={catPng} alt="" />
      <div className="shop_exchange_content">
        <div className="shop_content_title_section">
          <div className="shop_content_title">Exchange SHOP list</div>
          <div className="shop_content_title_sub">Many Goods can be exchanged now</div>
        </div>
        <div className="shop_content_bottom_wrap" style={{ width: '100%' }}>
          <div className="shop_content_bottom">
            <div className="shop_content_bottom_title">Live Good Amount / Total</div>
            <div className="shop_content_bottom_title_sub">{/* Continuously updated */}&nbsp;</div>
            <div className="shop_content_counter">
              <span>{totalStakedCount}</span>/617
            </div>
            <div className="shop_content_coming">* 0 Upcoming *</div>
          </div>
        </div>
      </div>
    </ShopListInfoWrap>
  )
}

export default ShopListInfo
