import styled from 'styled-components'

import modalBg from '../../assets/modalBg.png'

const ClaimModalWrap = styled.div`
  color: red;
  width: 100%;

  color: #6c5461;

  /* display: flex;
  align-items: center;
  justify-items: center; */

  width: 68%;
  margin: 5em auto 0;
  background-color: #ebe7e4;
  border: 0.125em solid #6c5461;
  border-radius: 1.25em;
  padding: 1.625em;
  background-image: url(${modalBg});
  background-size: 60% 100%;
  background-repeat: no-repeat;
  background-position: right;
  .modal-title {
    font-size: 1.5em;
    text-align: center;
    position: relative;
    font-weight: 400;
    font-weight: bolder;
    width: 100%;
    margin-bottom: 1em;
  }

  .modal-close-btn {
    background: #fbf9f9;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    border: 0px solid;
    padding: 0.1em;
    cursor: pointer;
    font-size: 1.2em;
  }

  .modal-selection-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
  }
  .modal-selection {
    opacity: 0.5;
  }
  .modal-total-staked {
    font-weight: 400;
  }

  .modal-staking-dashboard {
    opacity: 0.5;
    padding-top: 3em;
    text-align: left;
  }
  .modal-staking-reword {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-top: 1em;
    > span {
      opacity: 1;
      flex-shrink: 0;
    }

    .staking-type {
      text-decoration: underline;
    }
  }

  .staking-info-wrap {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-top: 1.2em;
  }

  .swap-title {
    font-size: 0.8em;
    opacity: 0.5;
    margin-bottom: 1em;
  }
  .swap-info {
    font-size: 1em;
  }
  .unstake-btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }
  .unstake-btn {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6c5461;
    padding: 1em;
    border-radius: 0.825em;
    cursor: pointer;
  }
  .modal-staking-nft-wrap {
    width: 100%;
    background-color: #fbf9f9;
    border-radius: 1.25em;
    font-size: 1em;
    max-height: 27em;
    overflow: scroll;
    margin-top: 1.2em;
    padding: 1.5em;
  }

  .progress-wrap {
    margin-top: 1em;
  }

  .empty-list-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 28%;
    margin: 0 auto;
    padding-top: 2em;
  }
  .empty-list-image {
    width: 100%;
  }
  .shake {
    -webkit-animation-name: shake_box;
    -ms-animation-name: shake_box;
    animation-name: shake_box;
    -webkit-animation-duration: 150ms;
    -ms-animation-duration: 150ms;
    animation-duration: 150ms;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    -ms-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  @keyframes shake_box {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      transform: translate(1.5px, -2.5px) rotate(-1.5deg);
    }
    40% {
      transform: translate(-2.5px, 0.5px) rotate(-0.5deg);
    }
  }
  @-ms-keyframes shake_box {
    0% {
      -ms-transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      -ms-transform: translate(1.5px, -2.5px) rotate(-1.5deg);
    }
    40% {
      transform: translate(-2.5px, 0.5px) rotate(-0.5deg);
    }
  }
  .unstake-active {
  }

  .unstake-disable {
    background: #d0d0d0;
  }

  .unstake-title {
    opacity: 0.5;
    font-size: 1em;
    padding: 0 8em;
    padding-bottom: 0.5em;
  }
  .unstake-title-red {
    padding-left: 1.25em;

    font-size: 1em;
    color: rgba(220, 28, 75, 1);
  }

  .unstake-nft-info {
    margin-top: 1.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25em;
    background-color: #fbf9f9;
    border-radius: 0.825em;
  }
  .unstake-nft-image-wrap {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .unstake-nft-image {
    width: 100%;
    margin-bottom: 1em;
  }
  .unstake-nft-detail {
    color: rgba(108, 84, 97, 0.5);
    flex: 1;
    div {
      display: flex;
      justify-content: space-between;
      padding: 0 0em 1em 3em;
    }
    span {
      color: #6c5461;
    }
  }

  .claim-btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .claim-btn {
    width: 35%;
    padding: 1em 0;
    color: #ffffff;
    background-color: #6c5461;
    border-radius: 0.825em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;
    cursor: pointer;
  }

  .mobile-detail {
    display: none;
  }

  .swap-icon-disabaled {
    display: none;
  }
  .color-red {
    color: rgba(220, 28, 75, 0.8);
  }
  .mobile-less {
    display: none;
  }

  /* xs */
  @media (max-width: 576px) {
    /* min-width: 576px; */
    width: auto;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100vh;
    margin-top: 0;
    border-radius: 0;
    border: 0;
    background-position: right;
    background-size: 100%;
    background-position-y: 0%;
    padding-top: 2em;
    overflow: scroll;
    .modal-title {
      text-align: left;
    }
    .modal-total-staked {
      display: none;
    }
    .unstake-nft-info {
      flex-direction: column;
    }
    .unstake-nft-image-wrap {
      width: 85%;
    }
    .unstake-nft-detail {
      margin-top: 1em;
      width: 100%;
      div {
        flex-direction: row;
        font-size: 0.75em;
        padding: 0.5em 0;
      }
    }

    .claim-btn {
      width: 100%;
    }

    .empty-list-wrap {
      width: 50%;
    }

    .staking-info-wrap {
      font-size: 0.75em;
    }

    .modal-staking-nft-wrap {
      max-height: initial;
      padding: 1.5em;
      img {
        width: 100%;
      }
    }
    .mobile-detail {
      display: block;
    }
    .mobil-detail {
      display: none;
    }

    .mobile-less {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.75em;
      margin-top: 3em;
    }

    .mobile-less-title {
      opacity: 0.5;
      margin-bottom: 0.75em;
    }
    .mobile-less-score {
      color: rgba(220, 28, 75, 0.8);
    }
    .mobile-less-up-icon {
      display: flex;
      align-items: center;
    }

    .mobile-show-detail {
      display: block;
    }
    .modal-staking-reword {
      flex-direction: column;
      display: flex;
      text-align: left;
      font-size: 0.75em;
    }
    .staking-type {
      margin-top: 1em;
      display: block;
    }
    .progress-wrap {
      margin-top: 0.75em;
    }
    .staking-info-wrap {
      flex-direction: column;
      text-align: left;
    }
    .swap-wrap {
      margin-bottom: 1.25em;
      position: relative;
    }
    .show-KeyboardDoubleArrowDownIcon {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
    }
    .modal-staking-dashboard {
    }
  }

  /* md */
  @media (min-width: 577px) {
    /* min-width: 1110px; */
    /* min-width: 760px; */

    .modal-title {
    }
  }
`

export default ClaimModalWrap
