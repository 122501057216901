type Unit = 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'
/**
 * 获取剩余时间
 */
export const getLeftTime = (timestamp: number, maxUnit?: Unit) => {
  if (timestamp <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }
  }
  // TODO:
  // const unitOpreareNum: Record<Unit, number> = {
  //   days: 24,
  //   hours: 60,
  //   minutes: 60,
  //   seconds: 60,
  //   milliseconds: 1000,
  // }
  const minutes = Math.floor((timestamp / 1000 / 60) % 60)
  const hours = Math.floor((timestamp / 1000 / 60 / 60) % 24)
  const days = Math.floor(timestamp / 1000 / 60 / 60 / 24)
  // Object.entries(unitOpreareNum).reduce((acc, [unit, num]) => {

  // })
  return {
    days,
    hours,
    minutes,
  }
}
