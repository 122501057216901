import React, { useEffect, useState, useRef } from 'react'
import Banner from './components/Banner'
import ShopListInfo from './components/showListInfo'
import { WalletConnect } from '../../components/WalletConnect'
import { Skeleton } from '../../components/Skeleton'
import useConnectWallect from './useConnectWallect'
import shopBanner from './assets/shopBanner.png'
import emptyList from './assets/emptyList.png'
import ShopPage from './style'
import WoodenFrame from './components/woodenFrame'
import { Header } from '../../components/Header'
import { ShopList } from './components/shop-list'

export const Shop = () => {
  const [currentTab, setCurrentTab] = useState<string>('HOME')
  const [show, setIsShow] = useState<boolean>(true)
  const [process, setProcess] = useState<any>(0)
  const timerRef = useRef<any>()
  const countRef = useRef<any>()
  const [size, setSize] = useState('')

  useEffect(() => {
    const viewport: any = document.getElementById('viewport')
    if (!viewport) return
    viewport.content = 'width=device-width,initial-scale=1.0'

    function init() {
      const width = document.documentElement.clientWidth
      const fontSize = 16 * (width / 1920)

      document.documentElement.style.fontSize = (fontSize > 12 ? fontSize : 12) + 'px'
    }
    init()
    window.addEventListener('orientationchange', init)
    window.addEventListener('resize', init)
  }, [])

  useEffect(() => {
    const resize = function () {
      if (window.innerWidth <= 576) {
        setSize('xs')
      } else {
        setSize('')
      }
    }
    resize()
    window.addEventListener('resize', resize, false)
    return () => {
      window.removeEventListener('resize', resize, false)
    }
  }, [])

  useConnectWallect()

  useEffect(() => {
    const imgList = [shopBanner, emptyList]
    const imgObj: any = {}
    countRef.current = 0
    for (let i = 0; i < imgList.length; i++) {
      const oimage = new Image()
      oimage.src = imgList[i]
      // eslint-disable-next-line no-loop-func
      oimage.onload = () => {
        if (!Object.prototype.hasOwnProperty.call(imgObj, oimage.src)) {
          imgObj[oimage.src] = true
          countRef.current += 1
          const num = ((countRef.current / imgList.length) * 100).toFixed(0)
          setProcess(Number(num))
          if (Number(num) >= 100) {
            timerRef.current = setTimeout(() => {
              setIsShow(false)
            }, 600)
          }
        }
      }
    }

    return () => {
      timerRef && timerRef.current && clearTimeout(timerRef.current)
    }
  }, [])

  return (
    <ShopPage id="SHOP">
      {!show && (
        <Header
          currentTab={currentTab}
          setCurrentTab={(tab: string) => {
            setCurrentTab(tab)
          }}
        />
      )}
      <Banner />
      <div className="shop_dashboard">
        <div className="shop_dashboard_text">
          <div className="shop_dashboard_text_title">Dashboard</div>
          <div className="shop_dashboard_subtext">View your assets in Wanghai village</div>
        </div>
        <div className="dashboard_btn_wrap" style={{ width: '190px' }}>
          <WalletConnect />
        </div>
      </div>
      <WoodenFrame size={size} />
      <ShopListInfo size={size} />
      <ShopList size={size} />
      {show && <Skeleton visible={show} progress={process} />}
    </ShopPage>
  )
}

export default Shop
