import styled from 'styled-components'

const ShopPage = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6c5461;
  overflow-y: scroll;

  /* dashobrad */
  .shop_dashboard_text_title {
    font-size: 1.75em;
  }

  .shop_dashboard_subtext {
    opacity: 0.5;

    font-size: 1em;
  }
  .headerSolt {
    display: none;
  }
  /* xs */
  @media (max-width: 576px) {
    overflow-y: scroll;

    /* min-width: 576px; */
    width: auto;
    /* overflow-x: hidden; */
    /* overflow-y: scroll; */
    .shop_dashboard {
      width: 100%;
      display: flex;
      padding: 1em 1em 0;
      flex-direction: column;
      .shop_dashboard_text {
        display: flex;
        flex-direction: column;
        display: flex;
      }
      .shop_dashboard_text-title {
        font-size: 1.25em;
      }
      .shop_dashboard_subtext {
        font-size: 0.8em;
        margin-bottom: 1.5em;
      }
      .dashboard_btn_wrap {
        width: 190px;
        display: flex;
        align-items: center;
        button {
          display: flex;
        }
      }
    }
  }

  /* md | mx */
  @media (min-width: 577px) {
    width: 100%;
    min-width: 1110px;
    .shop_dashboard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 73%;
      padding-top: 1em;
      margin-top: 8px;
      .shop_dashboard_text {
        display: flex;
        align-items: center;
      }
      .shop_dashboard_subtext {
        padding-left: 1em;
      }
      .dashboard_btn_wrap {
        // width: 14%;
      }
    }
  }
`

export default ShopPage
