import styled from 'styled-components'
import shopBannerPng from '../assets/shopBanner.png'

const BannerWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  .shop_banner {
    width: 100%;
  }
  .banner_footer {
    width: 100%;
    height: 8.8em;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(251, 249, 249, 0) 0%,
      #f5f5f5 63.32%
    );
    /* z-index: 10; */
    bottom: 0;
  }
  @media (max-width: 576px) {
    margin-top: 5em;
    .banner_footer {
      height: 1em;
      display: none;
    }
  }
`

const Banner: React.FC = () => (
  <BannerWrap>
    <img className="shop_banner" src={shopBannerPng} alt="banner" />
    <div className="banner_footer" />
  </BannerWrap>
)

export default Banner
