import { RulesStyled } from './style';
// import continuePng from './assets/continue.png'
// import { useIsMDDown } from '../../theme'
import ruleTemplePng from './assets/rule_temple.png'

export const Rules = () => {
  // const isMd = useIsMDDown(); // mobile

  return (
    <RulesStyled id='RULES'>
      <div className='rules_wrapper'>
        <div className="rules_contont">
          {/* <h2>Pledge<br />rules</h2> */}
          <h2>Art</h2>
          <h3>Introduction</h3>
          {/* <p className='rules_text'>Wanghai Village is a story-oriented NFT series featuring animation-style art, a collection of 1000 Genesis PASS cards and 3000 residents of Wanghai Village consisting of anime collections.</p>
          <p className="rules_text"> All we want to build is a stylish, loving, and mutually supportive community in web 3.0.</p> */}
          {/* <img src={continuePng} alt="" /> */}
          <p className="rules_text">The story of Wanghai Village and the style of the characters lean towards the old Chinese fishing village, combined with some fantasy elements and historical costumes, we want to show some diversified culture and art on our works, so that the world can understand some cultural history from China, you will see a better Wanghai Village in the future</p>
        </div>

        <img className='rule_bg' src={ruleTemplePng} alt="" />
      </div>
    </RulesStyled>
  )
}