import { Routes } from './Routes'
import './public/css/font.css'
import './public/css/global.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './web3/connectors'
import { ThemeProvider } from '@mui/material'
import theme from './theme'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ToastContainer />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Web3ReactProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
