import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Modal, Typography, Button, Grid, Box } from '@mui/material'
import { uid } from 'react-uid'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import NftCard from './nftCard'

import { INFTSTAKE, levelConfig, useShopContract } from '../../contractUtils'

import emptyList from '../../assets/emptyList.png'
import ModalContent from './style'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import moment from 'moment'

interface IStakeModal {
  open: boolean
  type?: 'stake' | 'unstake'
  handleOpen?: () => void
  handleClose: () => void
}

export const useStakeModal = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return {
    open,
    handleClose,
    handleOpen,
  }
}

const btnStatus = [30, 60, 90] // 1, 2,3

const StakeModal: React.FC<IStakeModal> = (props) => {
  // const { flag } = pageData.useContainer()
  const { open, handleClose } = props
  // const [staking, setStaking] = useState<number>(btnStatus[0])
  const [staking, setStaking] = useState<number>(btnStatus[0])
  const [list, setList] = useState<Array<INFTSTAKE>>([])
  const [step, setStep] = useState<number>(1)
  const [loading, setLoading] = useState(true)
  const { getUserNftsInfo, stake, approveNftToProxyAddress, queryHasApproved } = useShopContract()
  const [isPending, setIsPending] = useState<boolean>()
  const [isApproved, setIsApproved] = useState<boolean>()

  const handleActive = (idx: number) => {
    const newList = list.map((i, index) => {
      return {
        ...i,
        isActive: !!(idx === index),
      }
    })
    setList(newList)
  }

  const getList = useCallback(async () => {
    const listInfo = await getUserNftsInfo()
    // await getUserTokens()
    setList(listInfo)

    setTimeout(() => {
      setLoading(false)
    }, 200)
  }, [getUserNftsInfo])

  const queryApprovedStatus = useCallback(async () => {
    const hasApproved = await queryHasApproved()
    setIsApproved(hasApproved)
  }, [queryHasApproved])

  useEffect(() => {
    if (open && loading) {
      getList()
      setStep(1) // default step 1
      queryApprovedStatus()
    }
  }, [open, getList, queryApprovedStatus, loading])

  const canIClickStaking = list.filter((i) => !!i.isActive).length > 0

  const handleStaking = () => {
    if (!canIClickStaking) {
      return
    }
    setStep(2) // staking - comfirm
  }

  const handleApprove = async () => {
    const hasApproved = await queryHasApproved()

    // 没有授权先执行 NFT approve
    setIsPending(true)
    !hasApproved && setIsPending(true)
    toast.promise(
      approveNftToProxyAddress()
        .then(() => {
          setIsApproved(true)
        })
        .finally(() => {
          setIsPending(false)
        }),
      {
        pending: 'Approve transaction pending...',
        success: 'This transaction successful',
        error: 'This transaction send failed',
      },
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      },
    )
  }

  // function stake(uint256 tokenId, uint256 level_)
  const handleStakingComfirm = async () => {
    // selected tokenId ， stake level
    // handleClose()
    const tokenId = list.filter((i) => i.isActive)[0].tokenId
    console.log(tokenId, 'tokenId', staking / 30, 'level', 'stake-params')

    setIsPending(true)
    toast.promise(
      stake(tokenId, staking / 30)
        .then(() => {
          // 质押成功
          handleClose()
        })
        .finally(() => {
          setIsPending(false)
        }),
      {
        pending: 'Stake transaction pending...',
        success: 'This transaction successful',
        error: 'This transaction send failed',
      },
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      },
    )
  }

  // const getStakingNftInfo = async () => {}
  const { stakeTarget, poolConfig } = useMemo(() => {
    const stakeTarget = list.filter((i) => i.isActive)[0]
    const poolConfig = levelConfig[staking / 30]

    return {
      stakeTarget,
      poolConfig,
    }
  }, [list, staking])

  useEffect(() => {
    const viewport: any = document.getElementById('viewport')
    if (!viewport) return
    viewport.content = 'width=device-width,initial-scale=1.0'

    function init() {
      const width = document.documentElement.clientWidth
      const fontSize = 18 * (width / 1920)

      document.documentElement.style.fontSize = (fontSize > 12 ? fontSize : 12) + 'px'
    }
    init()
    window.addEventListener('orientationchange', init)
    window.addEventListener('resize', init)
  }, [])

  console.log('loading--->', loading)

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ fontSize: '16px' }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
            height: '100vh',
          }}
        >
          <ModalContent>
            {/* title */}
            <Typography
              sx={{
                textAlign: 'center',
                position: 'relative',
                color: '#6C5461',
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modal-title"
            >
              NFT Stake
              <CloseSharpIcon onClick={handleClose} fontSize="small" className="modal-close-btn" />
            </Typography>

            {/* stake content */}
            {step === 1 && (
              <Typography
                component="div"
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className="modal-description"
              >
                <div className="stake-section">
                  <span style={{ color: '#6C5461', opacity: 0.5 }}>
                    {step === 1 && 'Staking challenge selection'}
                  </span>
                  <span
                    className="btn-rules"
                    style={{
                      color: ' rgba(226, 72, 110, 1)61',
                      opacity: 1,
                      borderBottom: '1px solid',
                    }}
                  >
                    Rule
                  </span>
                </div>
                <div className="btn-group">
                  {btnStatus.map((i) => {
                    return (
                      <Button
                        key={i}
                        className={`btn-cell ${staking === i && 'btn-days-active'}`}
                        onClick={() => {
                          setStaking(i)
                        }}
                      >
                        {i} DAYS
                      </Button>
                    )
                  })}
                </div>
                <div className="score-tip">
                  {poolConfig.speed}, estimated earn {poolConfig.estimatedEarn} Score, Level{' '}
                  {poolConfig.level} Badge NFT
                </div>
                <div className="section-holding">
                  <div style={{ opacity: 0.5 }}>NFT Selection </div>
                  <div className="section-total-holding">Total {list.length} holding</div>
                </div>

                <Typography component="div" className="nft-list-outer-wrap">
                  <Box>
                    <Grid container spacing={2}>
                      {list.length ? (
                        list.map((nft, index) => (
                          <Grid item key={uid(nft)} xs={window.innerWidth <= 576 ? 12 : 3} md={3}>
                            <NftCard handleClick={() => handleActive(index)} {...nft} />
                          </Grid>
                        ))
                      ) : (
                        <div className="empty-list-wrap ">
                          <img
                            className={`empty-list-image  ${loading && 'shake'}`}
                            src={emptyList}
                            alt=""
                          />
                          <div style={{ color: '#6C5461', opacity: '0.5' }}>
                            {loading ? 'Loading...' : ' No NFT Holding'}
                          </div>
                          {!loading && (
                            <div
                              style={{
                                textDecoration: 'underline',
                                color: '#6C5461',
                                fontSize: '0.75em',
                                cursor: 'pointer',
                              }}
                            >
                              View on OpenSea
                            </div>
                          )}
                        </div>
                      )}
                    </Grid>
                  </Box>
                </Typography>

                <div className="stake-bottom">
                  <div
                    className={`stake-btn ${
                      canIClickStaking ? 'stake-btn-active' : 'stake-btn-disable'
                    }`}
                    onClick={handleStaking}
                  >
                    {loading ? 'Loading' : 'STAKE'}
                  </div>
                </div>
              </Typography>
            )}

            {step === 2 && (
              <Typography
                component="div"
                id="modal-modal-description"
                className="modal-description"
              >
                <div className="nft-stake-description-wrap">
                  <div className="nft-stake-description-text">
                    {/* 你在质押NFT进行挑战赚取积分，如果挑战中途退出奖励会减少，你确认进行质押吗？ */}
                    You challenge in the pledge NFT to earn points. You cannot quit in the middle of
                    the challenge. Do you confirm to pledge?
                  </div>
                  <div className="nft-stake-description-text-rule">Rule</div>
                </div>

                <div className="nft-card-detail-wrap">
                  <div className="nft-avator-wrap">
                    <img src={stakeTarget.image} alt="" />
                    <div>{stakeTarget.title}</div>
                  </div>
                  <div className="staking-text-section">
                    <div className="staking-title-common">Stake detail</div>
                    <div className="staking-title-wrap">
                      <div className="staking-title-common">Speed</div>
                      <div className="staking-title-common-value">{poolConfig.speed}</div>
                    </div>
                    <div className="staking-title-wrap">
                      <div className="staking-title-common">Duration</div>
                      <div className="staking-title-common-value"> {staking} Days</div>
                    </div>

                    <div className="staking-title-wrap">
                      <div className="staking-title-common">Challenge end</div>
                      <div className="staking-title-common-value">
                        {moment(Date.now() + poolConfig.stakePeriod * 24 * 3600 * 1000).format(
                          'MMM dd, YYYY HH:mm',
                        )}
                      </div>
                    </div>

                    <div className="staking-title-wrap">
                      <div className="staking-title-common">Estimated earn</div>
                      <div className="staking-title-common-value-submit">
                        {poolConfig.estimatedEarn} Score , Level {poolConfig.level} Badge NFT
                      </div>
                    </div>
                  </div>
                </div>

                <div className="staking-comfirm-btn-wrap">
                  {isApproved ? (
                    <div
                      className={classNames('staking-confirm-btn', isPending ? 'disabled' : '')}
                      onClick={() => {
                        !isPending && handleStakingComfirm()
                      }}
                    >
                      {isPending ? 'Pending...' : 'Confirm'}
                    </div>
                  ) : (
                    <div
                      className={classNames('staking-confirm-btn', isPending ? 'disabled' : '')}
                      onClick={() => {
                        !isPending && handleApprove()
                      }}
                    >
                      {isPending ? 'Approving...' : 'Approve'}
                    </div>
                  )}
                </div>
              </Typography>
            )}
          </ModalContent>
        </div>
      </Modal>
    </>
  )
}

export default StakeModal
