import React, { useState } from 'react'
import { HeaderStyled } from './style'
import logoPng from './assets/logo.png'
import { Link } from 'react-scroll'
import { WalletConnect } from '../WalletConnect'
import openseaPng from './assets/opensea.png'
import twitterPng from './assets/twitter.png'
import discordPng from './assets/discord.png'
import { uid } from 'react-uid'
import { useHistory, useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { Drawer } from '@mui/material'

const menuList = [
  {
    name: 'HOME',
  },
  {
    name: 'ABOUT',
  },
  {
    name: 'ROADMAP',
  },
  {
    name: 'TEAM',
  },
  {
    name: 'FAQ',
  },
  {
    name: 'SHOP',
    disabled: true,
  },
]

const socialList = [
  {
    name: 'opensea',
    icon: openseaPng,
    link: 'https://opensea.io/collection/wanghai-village-series',
  },
  {
    name: 'twitter',
    icon: twitterPng,
    link: 'https://twitter.com/WangHaiVillage',
  },
  {
    name: 'discord',
    icon: discordPng,
    link: 'https://discord.gg/nqN2MnbcA7',
  },
]

interface IHeader {
  currentTab: string
  setCurrentTab: (args: any) => void
}

export const Header: React.FC<IHeader> = (props) => {
  const { currentTab, setCurrentTab } = props
  const [preTabIndex, setPreTableIndex] = useState(0)
  const [sorollLock, setScrollLock] = useState(false)
  const [open, setOpen] = useState(false)

  const history = useHistory()

  const location = useLocation()
  const handleClickMenu = async (menuName: string, index: number) => {
    if (location.pathname.includes('/shop')) {
      if (menuName === 'SHOP') {
        return
      }
      history.push('/')
      return
    }
    if (menuName === 'SHOP') {
      history.push('/shop')
      return
    }
    setScrollLock(true)
    setCurrentTab(menuName)
    // 滑动到对应页面
    setTimeout(() => {
      setPreTableIndex(index)
      setScrollLock(false)
    }, Math.abs(index - preTabIndex) * 201)
  }

  return (
    <HeaderStyled>
      <div className="context_wrapper">
        <img src={logoPng} alt="" className="logo" />

        <ul className="menu">
          {menuList.map((menu, index) => {
            return (
              <Link
                key={menu.name}
                to={menu.name}
                spy={true}
                smooth={true}
                duration={Math.abs(index - preTabIndex) * 200}
                isDynamic={true}
                delay={100}
                onSetActive={(data) => {
                  !sorollLock && setCurrentTab(data)
                }}
              >
                <li
                  key={menu.name}
                  className={menu.name === currentTab ? 'active' : ''}
                  onClick={() => {
                    handleClickMenu(menu.name, index)
                  }}
                >
                  {menu.name}
                </li>
              </Link>
            )
          })}
        </ul>

        <div className="right_box">
          <div className="connect_wallet">
            <WalletConnect />
          </div>
          <ul className="social_box">
            {socialList.map((item) => {
              return (
                <li
                  className=""
                  key={uid(item)}
                  onClick={() => {
                    item.link && window.open(item.link)
                  }}
                >
                  <img src={item.icon} alt="" />
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      {!open && (
        <div className="context_wrapper_xs">
          <img src={logoPng} alt="" className="logo" />
          <MenuIcon
            onClick={() => {
              setOpen(true)
              console.log('click->')
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
      )}
      {open && (
        <div className="context_menu_drawer">
          <div className="menu_top">
            <img src={logoPng} alt="" className="logo" />
            <CloseIcon
              onClick={() => {
                setOpen(false)
              }}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <ul className="xs_menu">
            {menuList.map((menu, index) => {
              return (
                <Link
                  key={menu.name}
                  to={menu.name}
                  spy={true}
                  smooth={true}
                  duration={Math.abs(index - preTabIndex) * 200}
                  isDynamic={true}
                  delay={100}
                  onSetActive={(data) => {
                    !sorollLock && setCurrentTab(data)
                  }}
                >
                  <li
                    key={menu.name}
                    className={menu.name === currentTab ? 'active' : ''}
                    onClick={() => {
                      handleClickMenu(menu.name, index)
                    }}
                  >
                    {menu.name}
                  </li>
                </Link>
              )
            })}
          </ul>
          <div className="xs_menu_bottom">
            <div className="xs_menu_wallet">
              <WalletConnect />
            </div>
            <ul className="xs_menu_social_box">
              {socialList.map((item) => {
                return (
                  <li
                    className=""
                    key={uid(item)}
                    onClick={() => {
                      item.link && window.open(item.link)
                    }}
                  >
                    <img src={item.icon} alt="" />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
    </HeaderStyled>
  )
}
