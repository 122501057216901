import styled from 'styled-components';

export const TeamStyled = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  
    .team_wrapper {
      width: 70%;
      margin: 0 auto;

      .team_title{
        position: relative;

        h1{
          font-size: 8em;
          color: #FFFCE2;
          text-shadow: 4px 4px 13px #1659AC;
          z-index: 1;
        }

        .team_title_bg{
          position: absolute;
          width: 20%;
          left: 30%;
          top: -26%;
        }
      }

      .sub_title{
        width: 66%;
        font-family: 'Coco';
        font-size: 1.5em;
        line-height: 1.5em;
        color: #FFFFFF;
        margin-top: 2em;
      }


        ul.team_contont{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6em;

          li{
            width: 22%;
            padding: 1em;
            box-sizing: border-box;
            background-color: rgba(255,255,255,0.32);
            text-align: center;
            position: relative;

            img{
              width: 100%;
            }

            p.user_name{
              font-family: 'Coco';
              font-size: 2em;
              color: #fff;
              margin-top: 0.4em;
            }

            &:nth-child(2) p.user_name{
                font-size: 1.6em;
                margin-top: 0.6em;
                margin-bottom: 0.6em;
            }

            div.main{
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0.7em;
              margin-bottom: 0.25em;

              .learn_btn{
                width: 74%;
                height: 2.5em;
                box-sizing: border-box;
                font-size: 1.2em;
                font-family: TsangerXWZ;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                background: linear-gradient(-90deg, #DBF842 0%, #EB90B8 100%);
              
                &:hover{
                  transform: scale(1.05);
                  transition: all .3s;
                }
              }

              div{
                  width: 3em;
                  height: 3em;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 2px solid;
                  border-image: linear-gradient(-90deg, #DCEE4C, #EA92B5) 10 10;
                  cursor: pointer;

                  img{
                    width: 1.2em;
                  }
                }
            }

            div.desc_mask{
                  /* display: none; */
                  width: 110%;
                  height: 110%;
                  position: absolute;
                  background-color: rgba(0,0,0,.72);
                  z-index: 2;
                  top: -5%;
                  left: -5%;
                  color: #fff;
                  font-family: 'Coco';
                  box-sizing: border-box;
                  padding: 3em 2em;
                  line-height: 2em;
            }
        }
    }
  }
`