import React, { useEffect } from 'react'
import { CustomPopover, WalletConnectStyles } from './style'
import connectPng from './assets/connect.png'
import { connectors } from '../../web3/connectors'
import metamaskPng from './assets/metamask.png'
import walletConnectPng from './assets/walletconnect.png'
import { useWeb3React } from '@web3-react/core'
import { omitAddress } from '../../web3/utils/compare'
import exitSvg from './assets/exit.svg'

import { toast } from 'react-toastify'

export const WalletConnect = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { activate, deactivate, chainId, account } = useWeb3React()

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handelConnect = async (type: 'MetaMask' | 'WalletConnect') => {
    // console.log('>>>')
    handleClose()
    window.localStorage.setItem('provider', type)

    let isSuccess = true

    if (type === 'MetaMask')
      await activate(connectors.injected, (error: any) => {
        isSuccess = false
        toast.error(error?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        })
      }).then(async () => {
        // await sleep(0.5)
        isSuccess &&
          toast.success('Wallet connection successful', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          })
      })

    if (type === 'WalletConnect')
      await activate(connectors.walletConnect, (error: any) => {
        isSuccess = false
        toast.error(error?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        })
      }).then(async () => {
        // await sleep(0.5)
        isSuccess &&
          toast.success('Wallet connection successful', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          })
      })
  }

  const handelDisConncet = async () => {
    deactivate()
    window.localStorage.removeItem('provider')

    toast.warning('Disconnecting the wallet', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    })
  }

  useEffect(() => {
    console.log(chainId)
    console.log(account)
  }, [chainId, account])

  return (
    <WalletConnectStyles>
      {account ? (
        <div className="connected_box">
          <p title={account}>{omitAddress(account)}</p>
          <img src={exitSvg} alt="" title="disconnect" onClick={handelDisConncet} />
        </div>
      ) : (
        <>
          <button onClick={handleClick}>
            <img className="connect_btn" src={connectPng} alt="" />
          </button>
        </>
      )}

      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ backgroundColor: 'none' }}
      >
        <ul className="connect_ul">
          <li>
            <div
              onClick={() => {
                handelConnect('MetaMask')
              }}
            >
              <img src={metamaskPng} alt="" />
              <p>MetaMask</p>
            </div>
          </li>

          <li>
            <div
              onClick={() => {
                handelConnect('WalletConnect')
              }}
            >
              <img src={walletConnectPng} alt="" />
              <p className="walletConnect">WalletConnect</p>
            </div>
          </li>
        </ul>
      </CustomPopover>
    </WalletConnectStyles>
  )
}
