import { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/Header'
import { About } from '../About'
import { Home } from '../Home'
import { OverviewStyled } from './style'
import { Team } from '../Team'
import { Rules } from '../Rules'
import { FAQ } from '../FAQ'
import { Art } from '../Art'
import { Skeleton } from '../../components/Skeleton'
import { useSize } from 'ahooks'
import homeBg from '../Home/assets/home_bg.jpg'
import mintPng from '../Home/assets/mint.png'
import { useWeb3React } from '@web3-react/core'
import { connectors } from '../../web3/connectors'

export const Overview = () => {
  const [currentTab, setCurrentTab] = useState<string>('HOME')
  const ref = useRef(null)
  const size = useSize(ref)
  const [process, setProcess] = useState<any>(0)
  const [show, setIsShow] = useState<boolean>(true)
  const countRef = useRef<any>()
  const timerRef = useRef<any>()
  const [loadHomeVideo, setLoadHomeVideo] = useState<boolean>(false)

  const { active, activate } = useWeb3React()

  useEffect(() => {
    const viewport: any = document.getElementById('viewport')
    if (!viewport) return
    viewport.content = 'width=device-width,initial-scale=0.1'
  }, [])

  useEffect(() => {
    const imgList = [homeBg, mintPng]
    const imgObj: any = {}
    countRef.current = 0
    for (let i = 0; i < imgList.length; i++) {
      const oimage = new Image()
      oimage.src = imgList[i]
      // eslint-disable-next-line no-loop-func
      oimage.onload = () => {
        if (!Object.prototype.hasOwnProperty.call(imgObj, oimage.src)) {
          imgObj[oimage.src] = true
          countRef.current += 1
          const num = ((countRef.current / imgList.length) * 100).toFixed(0)
          setProcess(Number(num))
          if (Number(num) >= 100) {
            timerRef.current = setTimeout(() => {
              setIsShow(false)
            }, 600)
          }
        }
      }
    }

    return () => {
      timerRef && timerRef.current && clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    const walletName = window.localStorage.getItem('provider')
    if (!active && walletName) {
      if (walletName === 'MetaMask') activate(connectors.injected)
      if (walletName === 'WalletConnect') activate(connectors.walletConnect)
    }
  }, [active, activate])

  console.log(size, 'size')
  return (
    <OverviewStyled
      ref={ref}
      style={{ fontSize: size ? 16 * (size.width / 1920) : 16 }}
    >
      {show && !loadHomeVideo && <Skeleton visible={show} progress={process} />}
      <Header
        currentTab={currentTab}
        setCurrentTab={(tab: string) => {
          setCurrentTab(tab)
        }}
      />

      <Home handleLoadedState={(state) => setLoadHomeVideo(state)} />

      <div className="contont">
        <About />
        <Art />
        <Team />
        <Rules />
        <FAQ />
      </div>
    </OverviewStyled>
  )
}
