import styled from 'styled-components'

const WoodenFrameWrap = styled.div`
  width: 73%;
  margin: 0 auto;
  padding-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .wooden_frame_cell_wrap {
    color: #6c5461;
    font-weight: 400;
    position: relative;
    .wooden_frame_cell_bg {
      width: 100%;
      box-sizing: border-box;
    }

    .wooden_frame_cell_subtitle {
      opacity: 0.5;
    }
  }

  /* xs */
  @media (max-width: 576px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 1em 1em 0;

    .wooden_frame_cell_wrap {
      width: 100%;
      font-size: 0.825em;
      margin-bottom: 1em;
      height: 233px;
      .wooden_frame_cell_bg {
        height: 90%;
      }
      .wooden_frame_cell_content {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        top: 0;
        left: 0;
        height: 90%;
        padding: 3em 3.5em;
        font-size: 1em;
        .wooden_frame_cell_score {
          font-size: 3.6em;
        }
        .wooden_frame_cell_subtitle {
          font-size: 1.4em;
        }
        .wooden_frame_cell_btn {
          width: 38%;
          background: #6c5461;
          border-radius: 1em;
          font-size: 1em;
          color: #fff;
          cursor: pointer;
          font-weight: bolder;
          height: 33px;
        }
      }
    }
  }

  /* md */
  @media (min-width: 577px) {
    .wooden_frame_cell_wrap {
      width: 32.3%;
      margin-bottom: 2em;
      .wooden_frame_cell_content {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 2em 2em 2.5em 1.6em;

        .wooden_frame_text_content {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-top: 0.5em;
          font-size: 1.5em;
          .wooden_frame_cell_subtitle {
            font-size: 0.7em;
            padding-top: 0.4em;
          }
        }

        .wooden_frame_cell_score {
          font-size: 2em;
          text-align: right;
        }

        .wooden_frame_cell_btn {
          width: 28%;
          background: #6c5461;
          border-radius: 0.5em;
          color: #fff;
          cursor: pointer;
          font-weight: 700;
          height: 2.8em;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .wooden_frame_cell_wrap {
      .wooden_frame_cell_content {
        padding: 2em 2em 2.5em 1.6em;
      }
    }
  }
`

export default WoodenFrameWrap
