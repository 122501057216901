import { Contract, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState } from 'react'

import { SHOP_ADDRESS_Collection } from './contract/contractAddress'
import { printError } from '../../web3/utils/compare'

interface IPoolConfig {
  level: number
  rewardPerBlock: number // 多少积分/每秒
  stakePeriod: number // 当前时期 =》 状态
  speed: string
  estimatedEarn: number
  apr: string
}

export const levelConfig: Record<string, IPoolConfig> = {
  1: {
    level: 1,
    rewardPerBlock: 1,
    stakePeriod: 30,
    speed: '1 score / block',
    estimatedEarn: 172800,
    apr: '100%',
  },
  2: {
    level: 2,
    rewardPerBlock: 1.2,
    stakePeriod: 60,
    speed: '1.2 score / block',
    estimatedEarn: 414720,
    apr: '120%',
  },
  3: {
    level: 3,
    rewardPerBlock: 1.5,
    stakePeriod: 90,
    speed: '1.5 score / block',
    estimatedEarn: 777600,
    apr: '150%',
  },
}

export interface INFTSTAKE {
  tokenId: number
  title: string
  image: string
  score?: string
  isActive?: boolean // for stake Selected
}

export const useShopContract = () => {
  const { library, account } = useWeb3React()
  const [
    provider,
    setProvider,
  ] = useState<ethers.providers.Web3Provider | null>(null)
  // const [list, setList] = useState<Array<INFTSTAKE>>([])

  useEffect(() => {
    if (library) {
      console.log(library, 'library')
      const provider = new ethers.providers.Web3Provider(library.provider)
      console.log(provider, ' provider')
      setProvider(provider)
    }
  }, [library])

  const createTxNft = useCallback(async () => {
    if (!provider) {
      return
    }
    const CT = new ethers.Contract(
      SHOP_ADDRESS_Collection.WH_NFT.contract,
      SHOP_ADDRESS_Collection.WH_NFT.abi,
      provider,
    )
    return CT.connect(provider.getSigner())
  }, [provider])

  const createTxState = useCallback(async () => {
    if (!provider) {
      return
    }
    const CT = new ethers.Contract(
      SHOP_ADDRESS_Collection.WH_PROXY.contract,
      SHOP_ADDRESS_Collection.WH_STAKE.abi,
      provider,
    )
    return CT.connect(provider.getSigner())
  }, [provider])

  const queryHasApproved: () => Promise<boolean> = useCallback(async () => {
    try {
      const operatorAddress = (await createTxState())?.address
      const wanghaiNftContractWithSigner = await createTxNft()

      const hasApproved: boolean = await wanghaiNftContractWithSigner?.isApprovedForAll(
        account,
        operatorAddress,
      )
      // const isSuccess = await approveNftToProxyAddress(tokenId)
      console.log('hasApproved', hasApproved)
      return hasApproved
    } catch (error) {
      return false
    }
  }, [createTxNft, createTxState])

  const approveNftToProxyAddress = useCallback(async () => {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        // const tx = await createTxNft()
        // const res = await tx?.getApproved(tokenId)
        // if (res === SHOP_ADDRESS_Collection.WH_PROXY.contract) {
        //   console.log(res, 'res--->', account)
        // }
        // if (res) {
        //   console.log('true - approve', res, account)
        //   resolve(true)
        // }
        // await tx?.approve(SHOP_ADDRESS_Collection.WH_PROXY.contract, tokenId)
        // console.log(tx, 'tx--approve')
        // resolve(true)
        const operactor = (await createTxState())?.address
        const wanghaiNftContractWithSigner = await createTxNft()
        let approveArgs = [operactor, true]
        await wanghaiNftContractWithSigner?.estimateGas.setApprovalForAll(
          ...approveArgs,
        )
        const approvetransaction = (await wanghaiNftContractWithSigner?.setApprovalForAll(
          ...approveArgs,
        )) as ethers.ContractTransaction
        console.log('approve hash', approvetransaction.hash)
        await approvetransaction.wait()

        resolve(true)
      } catch (error) {
        printError(error)
        reject(error)
      }
    })
  }, [createTxNft])

  // uint256 tokenId, uint256 level_
  const stake = useCallback(
    async (tokenId: number, level: number) => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          console.log('stake-start')
          const stakeContractWithSigner = await createTxState()
          await stakeContractWithSigner?.estimateGas.stake(tokenId, level)
          const stakeTransaction = (await stakeContractWithSigner?.stake(
            tokenId,
            level,
          )) as ethers.ContractTransaction
          console.log('tx--stake' + stakeTransaction.hash)
          await stakeTransaction.wait()
          resolve(true)
        } catch (error) {
          printError(error)
          reject(error)
        }
      })
    },
    [approveNftToProxyAddress, createTxState],
  )

  // unstake uint256 tokenId
  const claim = useCallback(
    async (tokenId: number) => {
      return new Promise<boolean>(async (resolve, reject) => {
        try {
          const tx = await createTxState()
          tx?.unStake(tokenId)
          await tx?.wait()
          resolve(true)
        } catch (error) {
          printError(error)
          reject(error)
        }
      })
    },
    [createTxState],
  )

  const getUserNftsInfo = useCallback(async (): Promise<INFTSTAKE[]> => {
    if (!account) {
      console.log('test')
      return []
    }
    const tx = await createTxNft()
    const num = await tx?.balanceOf(account)
    const tokenInfos = [] as INFTSTAKE[]
    const length = num?.toNumber()
    for (let i = 0; i <= length - 1; i++) {
      // owner: address , index number
      const tokenId = await tx?.tokenOfOwnerByIndex(account, i)
      // need to get metadata info
      tokenInfos.push({
        tokenId: tokenId.toNumber(),
        title: `Genesis #${tokenId}`,
        image: `https://wanghaivillage.s3.amazonaws.com/product/image/${tokenId}.png`,
      })
    }
    return tokenInfos
  }, [account, createTxNft])

  const getUserTokens = useCallback(async () => {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!account) {
        reject([])
      }
      try {
        const tx = await createTxState()
        const stakedTokenIds = await tx?.userTokens(account)
        console.log(stakedTokenIds, '???_>')
        await tx?.wait()
        resolve(stakedTokenIds)
      } catch (error) {
        printError(error)
        reject(error)
      }
    })
  }, [account, createTxState])

  return {
    stake,
    claim,
    getUserNftsInfo,
    getUserTokens,
    approveNftToProxyAddress,
    queryHasApproved,
  }
}
