import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector, WalletConnectConnectorArguments } from "@web3-react/walletconnect-connector";
import { ethers } from "ethers";

const injected = new InjectedConnector({
    supportedChainIds: [1, 5]
});

const WALLET_RPC_URLS: Record<number, string> = {
  5: 'https://goerli.infura.io/v3/8e4d917818d94470a9d5c18326211f95'
}

const walletconnect = new WalletConnectConnector({
    // rpc: `https://goerli.infura.io/v3/8e4d917818d94470a9d5c18326211f95`,
    // rpcUrl: `https://goerli.infura.io/v3/8e4d917818d94470a9d5c18326211f95`,
    rpc: WALLET_RPC_URLS,
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
} as WalletConnectConnectorArguments & { rpcUrl: string });

export const connectors = {
    injected: injected,
    walletConnect: walletconnect
};

export const networkParams = {
    "0x63564c40": {
      chainId: "0x63564c40",
      rpcUrls: ["https://api.harmony.one"],
      chainName: "Harmony Mainnet",
      nativeCurrency: { name: "ONE", decimals: 18, symbol: "ONE" },
      blockExplorerUrls: ["https://explorer.harmony.one"],
      iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"]
    },
    "0xa4ec": {
      chainId: "0xa4ec",
      rpcUrls: ["https://forno.celo.org"],
      chainName: "Celo Mainnet",
      nativeCurrency: { name: "CELO", decimals: 18, symbol: "CELO" },
      blockExplorerUrl: ["https://explorer.celo.org"],
      iconUrls: [
        "https://celo.org/images/marketplace-icons/icon-celo-CELO-color-f.svg"
      ]
    }
  };

  export const getLibrary = (provider: any) => {
    const library = new ethers.providers.Web3Provider(provider);
    library.pollingInterval = 8000; // frequency provider is polling
    return library;
  };
  