import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Modal, Typography, Box, Grid } from '@mui/material'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import emptyList from '../../assets/emptyList.png'
import ClaimModalWrap from './style'
import { uid } from 'react-uid'
import { nftList } from '../StakeModal/static'
import NftCard from '../StakeModal/nftCard'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { useHook } from '../useHook'
import moment from 'moment'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import { StakedToken } from '../../types'
import { CustomizedProgressBars } from './Progress'
import { getLeftTime } from '../../../../utils'
import { log } from 'console'
import { sleep } from '../../../../web3/utils/thread'
import BigNumber from 'bignumber.js'
const stakingSwap = [
  {
    title: 'Locked time',
    info: '0 Day 15 H 30 Min',
  },
  {
    title: 'Locked APR',
    info: 'Up to 50.2%',
  },
  {
    title: 'Total earnede',
    info: '235,000 Score',
  },
]
const APRObj: Record<number, string> = {
  1: '100%',
  2: '120%',
  3: '150%',
}
interface IClaimModal {
  open: boolean
  type?: 'stake' | 'unstake'
  handleOpen?: () => void
  handleClose: () => void
}
const isEnd = (endTimestamp: number) => Math.floor(Date.now() / 1000) > endTimestamp
const ClaimModal: React.FC<IClaimModal> = (props) => {
  const { open, handleClose } = props
  const [list, setList] = useState<
    (StakedToken & { isActive: boolean; locked?: boolean; score: string })[]
  >([])

  const [step, setStep] = useState<number>(1)
  const [showMore, setShowMore] = useState(false)
  const { userStakedTokens, unStakeAndClaim } = useHook()
  const [isPending, setIsPending] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [currentToken, setCurrentToken] = useState<typeof list[number]>()

  const handleActive = (idx: number) => {
    const newList = list.map((i, index) => {
      return {
        ...i,
        isActive: !!(idx === index),
      }
    })
    setList(newList)
  }

  // useEffect(() => {
  //   setCurrentToken(userStakedTokens.find(item => item.tokenId === currentTokenIndex))
  // }, [currentTokenIndex, userStakedTokens])

  const handleUnstake = () => {
    canIClickUnstaking && setStep(2)
  }

  const handleClaim = useCallback(async () => {
    setIsPending(true)
    if (!currentToken) return
    console.log(currentToken)
    toast.promise(
      unStakeAndClaim(currentToken.tokenId)
        .then(() => {
          // 解除质押成功
        })
        .finally(() => {
          setIsPending(false)
          handleClose()
        }),
      {
        pending: 'Unstake transaction pending...',
        success: 'This transaction successful',
        error: 'This transaction send failed',
      },
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      },
    )
  }, [unStakeAndClaim, currentToken])

  const handleShowMore = () => {
    setShowMore(true)
  }
  const handleShowLess = () => {
    setShowMore(false)
  }

  useEffect(() => {
    if (open) {
      updateTokens()
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }, [open, userStakedTokens])
  const updateTokens = useCallback(() => {
    setList(
      userStakedTokens.map((item) => {
        const end = isEnd(item.endTimestamp)
        const targetTimestamp = end ? item.endTimestamp : Math.floor(Date.now() / 1000) - 15
        return {
          ...item,
          // image: item.image,
          // title: item.title,
          score: `${new BigNumber(targetTimestamp - item.startTimestamp).multipliedBy(item.rewardPerSecond).dp(4).toNumber()} SCORES`,
          isActive: false,
          locked: !end,
        }
      }),
    )
  }, [userStakedTokens])
  useEffect(() => {
    function resize() {
      window.innerWidth <= 576 && setShowMore(false)
    }
    window.addEventListener('resize', resize, false)
    return () => {
      window.removeEventListener('resize', resize, false)
    }
  }, [])
  const currentTokenInfo = useMemo(() => {
    if (!currentToken) {
      return
    }
    const end = isEnd(currentToken.endTimestamp)
    const { days, hours, minutes } = getLeftTime(currentToken.endTimestamp * 1000 - Date.now())
    return {
      leftTime: `${days} D : ${hours} H : ${minutes} M`,
      totalDays: moment(currentToken.startTimestamp * 1000).diff(
        currentToken.endTimestamp * 1000,
        'days',
      ),
      currentDays: moment(currentToken.startTimestamp * 1000).diff(
        end ? currentToken.endTimestamp * 1000 : Date.now(),
        'days',
      ),
    }
  }, [currentToken])
  const canIClickUnstaking = list.filter((i) => !!i.isActive).length > 0 && !currentToken?.locked

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <>
          {step === 1 && (
            <ClaimModalWrap>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modal-title"
              >
                Unstake NFT and claim rewards
                <CloseSharpIcon
                  onClick={handleClose}
                  fontSize="small"
                  className="modal-close-btn"
                />
              </Typography>
              <div className="modal-selection-wrap">
                <div className="modal-selection">NFT Selection </div>
                <div className="modal-total-staked">Total {userStakedTokens.length} Staked</div>
              </div>
              <div className="modal-staking-nft-wrap">
                <Grid container spacing={2}>
                  {list.length ? (
                    list.map((nft, index) => {
                      return (
                        <Grid
                          item
                          xs={window.innerWidth <= 576 ? 12 : 3}
                          md={3}
                          lg={3}
                          key={uid(nft)}
                        >
                          <NftCard
                            handleClick={async () => {
                              // const tar = userStakedTokens.find(item => item.tokenId === nft.token)
                              // console.log(userStakedTokens)
                              // console.log(nft)
                              nft && setCurrentToken(nft)
                              handleActive(index)
                            }}
                            {...nft}
                          />
                        </Grid>
                      )
                    })
                  ) : (
                    <div className="empty-list-wrap">
                      <img
                        // className="empty-list-image shake"
                        className={`empty-list-image  ${loading && 'shake'}`}
                        src={emptyList}
                        alt=""
                      />
                      <div style={{ color: '#6C5461', opacity: '0.5' }}>
                        {loading ? 'Loading...' : ' No NFT Holding'}
                      </div>
                      {loading ? (
                        ''
                      ) : (
                        <div
                          style={{
                            textDecoration: 'underline',
                            color: '#6C5461',
                            fontSize: '0.75em',
                            cursor: 'pointer',
                          }}
                        >
                          View on OpenSea
                        </div>
                      )}
                    </div>
                  )}
                </Grid>
              </div>
              {/* detail */}
              {currentTokenInfo && currentToken && (
                <div className={`mobil-detail ${showMore && 'mobile-show-detail'}`}>
                  <div className="modal-staking-dashboard">Staking Dashboard</div>
                  <div className="modal-staking-reword">
                    {list.length ? (
                      <div>
                        Total of 5,000 Scores and one NFT reward for the challenge, no quitting in
                        the middle of the challenge.
                      </div>
                    ) : (
                      <div>Get rewards for participating in challenges</div>
                    )}
                    <span className="staking-type color-red">
                      {currentTokenInfo.currentDays}/{currentTokenInfo.totalDays} Days
                    </span>
                  </div>
                  <Box className="progress-wrap">
                    <CustomizedProgressBars
                      value={(currentTokenInfo.currentDays / currentTokenInfo.totalDays) * 100}
                    />
                  </Box>
                  <div className="staking-info-wrap">
                    <div>
                      <div className="swap-wrap">
                        <div className="swap-title">Lock time Left</div>
                        <div className={`swap-info`}>{currentTokenInfo.leftTime}</div>
                      </div>
                    </div>
                    <div className="swap-wrap">
                      <div className="swap-title">LOCKED APR</div>
                      <div className={`swap-info`}>{APRObj[currentToken.level]}</div>
                    </div>
                    <div className="swap-wrap">
                      <div className="swap-title">Total Received</div>
                      <div className={`swap-info color-red`}>{currentToken.score}</div>
                      <span
                        className={`swap-icon-disabaled ${showMore && 'show-KeyboardDoubleArrowDownIcon'
                          }`}
                        onClick={handleShowLess}
                      >
                        less <KeyboardDoubleArrowDownIcon />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* total eran */}
              {!showMore && (
                <div className="mobile-less">
                  <div className="mobile-less-text-wrap">
                    <div className="mobile-less-title">Total earned</div>
                    <div className="mobile-less-score">235,000 Score</div>
                  </div>

                  <div className="mobile-less-up-icon" onClick={handleShowMore}>
                    detail <KeyboardDoubleArrowUpIcon />
                  </div>
                </div>
              )}

              <div className="unstake-btn-wrap">
                <div
                  className={`
              unstake-btn ${canIClickUnstaking ? 'unstake-active' : 'unstake-disable'}
            `}
                  onClick={handleUnstake}
                >
                  {currentToken?.locked ? 'Still Locked' : loading ? 'Loadding' : 'Unstake & Claim'}
                </div>
              </div>
            </ClaimModalWrap>
          )}
          {step === 2 && (
            <ClaimModalWrap>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modal-title"
              >
                Unstake NFT and claim rewards
                <CloseSharpIcon
                  onClick={handleClose}
                  fontSize="small"
                  className="modal-close-btn"
                />
              </Typography>
              <div className="unstake-title">
                Congratulation! You successfully completed the 30-day staking challenge. You can
                claim back your NFT, 5000 Scores and a badge NFT
              </div>
              {/* <div className="unstake-title-red">
                你当前质押了23天，只能获得奖励的70%，请谨慎确认
              </div> */}
              <div className="unstake-nft-info">
                {currentToken && (
                  <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <NftCard {...currentToken} score={undefined} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <NftCard
                        title={currentToken.score}
                        image={require('../../assets/score.svg').default}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <NftCard
                        title={`LEVEL ${currentToken.level} BADGE`}
                        image={require(`../../assets/level${currentToken.level}.png`)}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* <div className="unstake-nft-image-wrap">
                  <img className="unstake-nft-image" src={currentToken.image} alt="" />
                  <div>{currentToken.title}</div>
                </div>
                <div className="unstake-nft-detail">
                  <div>Stake detail</div>
                  <div>
                    APR{' '}
                    <span>
                      {currentToken.level === 2
                        ? '120%'
                        : currentToken.level === 3
                        ? '150%'
                        : '100%'}
                    </span>
                  </div>
                  <div>
                    Locked time{' '}
                    <span>
                      {
                        // moment.duration(currentToken.endTimestamp - currentToken.startTimestamp, "second").humanize(true)
                        currentToken.level === 2
                          ? '60 Days'
                          : currentToken.level === 3
                          ? '90 Days'
                          : '30 Days'
                      }
                    </span>
                  </div>
                  <div>
                    Total earned <span>{currentToken.score} , 1 NFT</span>
                  </div>
                </div> */}
              </div>
              <div className="claim-btn-wrap">
                <div className="claim-btn" onClick={handleClaim}>
                  {isPending ? 'pending...' : 'claim'}
                </div>
              </div>
            </ClaimModalWrap>
          )}
        </>
      </Modal>
    </>
  )
}

export default ClaimModal
