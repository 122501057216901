import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { Overview } from './page/Overview'
// import { Mint } from './page/Mint'
import Shop from './page/Shop'

export const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Overview} />
        {/* <Route exact path="/Mint" component={Mint} /> */}
        <Route exact path="/shop" component={Shop} />
      </Switch>
    </BrowserRouter>
  )
}

// import { Route, Routes as Router } from 'react-router-dom'

// export const Routes = () => {
//   return (
//     <div>
//       <Router>
//         <Route exact path="/" component={Overview} />
//         <Route exact path="/mint" component={Overview} />
//       </Router>
//     </div>
//   )
// }
