import styled from 'styled-components'
import contontBgJpg from './assets/contont_bg.jpg'

export const OverviewStyled = styled.div`
    position: relative;
    width: 100%;
    min-width: 1000px;
    overflow: hidden;

    @media(max-Width: 576){
        max-width: 576px;
        width: 100%;
    }

    .contont{
        width: 100%;
        min-width: 1000px;
        overflow: hidden;
        background-image: url(${contontBgJpg});
        background-repeat: no-repeat;
        /* background-size: 100% 100%; */
        background-size: 100% auto;
        background-position: 0 0;
    }
`