import { List, ListItem, ListItemText, SxProps, Theme } from '@mui/material'
import styled from 'styled-components'
import { omitAddress } from '../../../../web3/utils/compare'
import leaderboardImg from '../../assets/leaderboardImg.png'
import leaderboardImgRight from '../../assets/leaderboardImgRight.png'
import { GoodsList } from './GoodsList'
import { useHook } from '../useHook'
const ShopListWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  padding-bottom: 2em;
  gap: 2.5rem;
  @media (max-width: 576px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 577px) {
    width: 73%;
  }
`
const LeaderboardWrap = styled.div`
  width: 34.287%;
  border-radius: 1.25em;
  overflow: hidden;
  background: #433846;

  // xs
  @media (max-width: 576px) {
    width: 100%;
    border-radius: 0em;
    margin-bottom: 2em;
  }

  color: #fff;
  .leaderboard-img-section {
    flex-shrink:0,
    width: 100%;
  }
  .leaderboard-img {
    width: 86%;
  }
  .leaderboard-img-right {
    width: 14%;
  }
  .listWrap {
    width: 100%;
    font-size: 1em;
    line-height: 1.2;
    border-radius: 1.25em;

    li {
      padding-top: 0;
      padding-bottom: 0;
      div {
        span {
        }
      }
    }
  }
  .list-title {
    font-size: 1.5em;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    padding-top: 1em;
  }
`

interface IListCard {
  rank: string
  addewss: string
  score: number | string
  listItemStyle?: SxProps<Theme> | undefined
}
const ListCard: React.FC<IListCard> = ({ rank, addewss, score, listItemStyle }) => {
  return (
    <ListItem sx={listItemStyle}>
      <ListItemText primary={rank} sx={{ width: '20%', textAlign: 'left' }} />
      <ListItemText primary={addewss} sx={{ width: '50%', textAlign: 'center' }} />
      <ListItemText primary={score} sx={{ width: '30%', textAlign: 'right' }} />
    </ListItem>
  )
}

// left section
const Leaderboard = () => {
  const { rankingList } = useHook()

  return (
    <LeaderboardWrap>
      <div className="leaderboard-img-section">
        <img className="leaderboard-img" src={leaderboardImg} alt="" />
        <img className="leaderboard-img-right" src={leaderboardImgRight} alt="" />
      </div>
      <div className="list-title">Leaderboard</div>
      <div className="listWrap">
        <List sx={{ width: '100%', color: '#fff' }}>
          <ListCard
            listItemStyle={{ color: '#fff', opacity: 0.5 }}
            key="title"
            rank="RANK"
            addewss="ADDRESS"
            score="SCORE"
          />
          {rankingList.map((item, index) => {
            return (
              <ListCard
                key={item.address}
                rank={`${index + 1}`}
                addewss={omitAddress(item.address)}
                score={item.score}
              />
            )
          })}
          {/* <ListCard key="01" rank="01" addewss="0xb24...6f8b" score="20,200" />
          <ListCard key="02" rank="02" addewss="0xb24...6f5b" score="20,300" /> */}
        </List>
      </div>
    </LeaderboardWrap>
  )
}

// right section
// const ListSectionWrap = styled.div`
//   width: 62.86%;
//   background: rgba(255, 255, 255, 0.01);
//   backdrop-filter: blur(10px);
//   .list_empty_png {
//     width: 100%;
//   }
//   @media (max-width: 576px) {
//     width: 100%;
//   }
// `
// const ListSection = () => {
//   return (
//     <ListSectionWrap>
//       <img className="list_empty_png" src={emptyListPng} />
//     </ListSectionWrap>
//   )
// }

export const ShopList: React.FC<{ size: string }> = (props) => {
  const { size } = props
  const isMobile = size === 'xs'

  return (
    <ShopListWrap>
      <Leaderboard />
      <GoodsList />
    </ShopListWrap>
  )
}
