import { FaqStyle } from './style'
import { useState } from 'react'
import { uid } from 'react-uid'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

export const FAQlist = [
  {
    title: 'What is WangHai Village？How big is the collection？',
    answer:
      'WangHai Village is a story-oriented NFT series featuring animation-style art, an anime collection of 617 Genesis pfps.',
  },
  { title: 'How much is the mint？', answer: '0.05ETH' },
  { title: 'When is the mint？', answer: 'Whitelist mint is 2022-11-23 23:00. Public mint is 2022-11-24 00:00（utc+8）.' },
  {
    title: 'When is the reveal？',
    answer: `We will reveal at the end of December`,
  },
  { title: 'What are the maximum mint per wallet?', answer: 'Max mint 1 per wallet' },
  {
    title: 'What are the rights and benefits of Genesis NFT holders?',
    answer: `Holders of Genesis will have full commercial art rights. More information will be announced soon.`,
  },
  {
    title: 'What are secondary royalties and how will they be used?',
    answer: `All royalty proceeds from our secondary market transactions will be used to hire more full-time team members, produce comics and fund operations to provide more value to the community`,
  },
]

export const FAQ = () => {
  const [expanded, setExpanded] = useState<number | false>(0);


  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <FaqStyle id='FAQ'>
      <div className='faq_wrapper'>
        <div className='faq-head'>
          FAQ
        </div>

        <ul className='faq-content'>
          {FAQlist.map((item, index) => {
            return <li key={uid(item)}>
              <Accordion className='faq_box' expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography className='faq_title'>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='faq_anwser'>
                    {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
          })}
        </ul>
      </div>
    </FaqStyle>
  )
}