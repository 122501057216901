import styled from 'styled-components'

export const HomeStyled = styled.div`
    position: relative;
    box-sizing: border-box;

    .white_box{
        height: 5em;
    }

    .bg{
        width: 100%;
    }

    .mint_btn{
        position: absolute;
        width: 30%;
        left: 13%;
        bottom: 9%;
        cursor: pointer;
    }
`