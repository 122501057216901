import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import { FC } from 'react'
import { useIsLGDown, useIsSMDown } from '../../../../theme'

export interface GoodsProps {
  imgUrl: string
  title: string
  desc: string
  score: string
  state: string
  date: string
}
const StyledBox = styled(Box)({
  '@media (max-width: 1200px)': {
    '.score': {
      fontSize: '1.5rem',
    },
    '.score-desc': {
      fontSize: '.875rem',
    },
  },
  '@media (max-width: 600px)': {
    '.score-desc': {
      display: 'block',
    },
  },
})
export const Goods: FC<GoodsProps> = ({ imgUrl, title, date, score, state, desc }) => {
  const isDownLg = useIsLGDown()
  const isDownSm = useIsSMDown()
  return (
    <StyledBox
      sx={{
        position: 'relative',
        height: '12.68rem',
        borderRadius: '0.625rem',
        backgroundColor: '#ebe7e4',
        padding: '1.25rem 1.875rem',
        display: 'flex',
        gap: '1.25rem',
      }}
      className="h-50 rounded-10 flex"
    >
      <img src={imgUrl} style={{ flexShrink: 0, width: '10rem', height: '10rem' }} alt="" />
      <Box
        sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
      >
        <Box style={{ color: '#6C5461', fontSize: '1.5rem' }}>{title}</Box>
        <Box sx={{ color: '#6C5461', fontSize: '1rem', opacity: '0.5' }}>{desc}</Box>
        <Box sx={{ color: '#958b83' }}>{date}</Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            disabled
            size={isDownSm ? 'small' : isDownLg ? 'medium' : 'large'}
            sx={{
              backgroundColor: '#a5a4a4 !important',
              color: '#fff !important',
              borderRadius: '0.625rem !important',
              flexShrink: 0,
              // fontSize: '0.875rem',
            }}
          >
            Not Started Yet
          </Button>
          <Box
            className="score"
            sx={{
              color: '#6C5461',
              fontSize: '2rem',
              alignItems: 'baseline',
            }}
          >
            {score}
            <Box
              className="score-desc"
              sx={{ fontSize: '1.25rem', ml: '.125rem', display: 'inline-block' }}
            >
              &nbsp;&nbsp;scores
            </Box>
          </Box>
        </Box>
      </Box>
      <img
        src={require('../../assets/goods/decorator.png')}
        alt=""
        style={{
          position: 'absolute',
          width: '100%',
          left: 0,
          bottom: 0,
          transform: 'translateY(70%)',
        }}
      />
    </StyledBox>
  )
}
