import styled from 'styled-components'
import modalBg from '../../assets/modalBg.png'

export const ModalWrap = styled.div``

const ModalContent = styled.div`
  width: 56%;
  margin: 0 auto;
  /* max-height: 40em; */
  background: #ebe7e4;
  border: 0.125em solid #6c5461;
  border-radius: 1.25em;
  padding: 1.625em;

  background-image: url(${modalBg});
  background-size: 60% 100%;
  background-repeat: no-repeat;
  background-position: right;

  .modal-close-btn {
    background: #fbf9f9;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    border: 0px solid;
    padding: 0.1em;
    cursor: pointer;
    font-size: 1.2em;
  }

  .modal-title {
    text-align: center;
    position: relative;
    color: #6c5461;
    font-weight: bolder;
    font-size: 1.3em;
  }

  .btn-group {
    padding-top: 1em;
    display: flex;
    align-items: center;

    .btn-cell {
      color: #6c5461;
      font-weight: bolder;
      color: #6c5461;
      font-size: 1.1em;
      padding: 0.4em 0;
      background: #fff8eb;
      border: 1px solid #6c5461;
      border-radius: 0.625em;
      cursor: pointer;
    }
    .btn-days-active {
      background: #6c5461;
      color: #fff8eb;
    }
  }
  .score-tip {
    font-weight: bolder;
    font-size: 1.3em;
    display: flex;
    align-items: flex-end;
    color: #6c5461;
    padding: 1em 0 1.5em;
    line-height: 1;
  }
  .stake-section {
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    color: #6c5461;
    justify-content: space-between;
    font-size: 1.2em;
    .btn-rules {
      color: rgba(226, 72, 110, 1);
      font-size: 1.2em;
    }
  }

  .section-holding {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6c5461;

    font-weight: bolder;
    font-size: 1.25em;
    margin-bottom: 1em;
  }

  .stake-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 1.2em;
  }

  .stake-btn {
    display: block-inline;
    padding: 1em 5.125em;
    border-radius: 0.625em;
    cursor: pointer;
    color: #ffffff;
  }
  .stake-btn-active {
    background: #6c5461;
  }
  .stake-btn-disable {
    background: #d0d0d0;
  }

  .empty-list-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .empty-list-image {
    width: 20%;
    padding: 1em 0;
  }
  .shake {
    -webkit-animation-name: shake_box;
    -ms-animation-name: shake_box;
    animation-name: shake_box;
    -webkit-animation-duration: 150ms;
    -ms-animation-duration: 150ms;
    animation-duration: 150ms;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    -ms-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  @keyframes shake_box {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      transform: translate(1.5px, -2.5px) rotate(-1.5deg);
    }
    40% {
      transform: translate(-2.5px, 0.5px) rotate(-0.5deg);
    }
  }
  @-ms-keyframes shake_box {
    0% {
      -ms-transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      -ms-transform: translate(1.5px, -2.5px) rotate(-1.5deg);
    }
    40% {
      transform: translate(-2.5px, 0.5px) rotate(-0.5deg);
    }
  }
  /* xs */
  @media (max-width: 576px) {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    border-radius: 0;
    border: 0;
    background-position: right;
    background-size: 100%;
    background-position-y: 0%;

    .modal-title {
      padding-top: 1em;
      text-align: left;
      line-height: 1.5;
      .modal-close-btn {
      }
    }
    .stake-section {
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      align-items: flex-start;
      line-height: 1.8;
    }

    .btn-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > button {
        margin-top: 1.2em;
        width: 36%;
        padding: 0.6em 0 !important;
      }
    }
    .mgrl {
      margin-right: 0;
      margin-left: 0;
    }
    .stake-bottom {
      margin-top: 1em;
      position: fixed;
      bottom: 0;
      width: 100%;
      background: #fbf9f9;
      left: 0px;
    }
    .score-tip {
      padding-top: 1.5em;
      font-weight: bolder;
      font-size: 1.3em;
    }

    .section-total-holding {
      display: none;
    }
    .nft-list-outer-wrap {
      width: 100%;
      padding: 1.5em 1.25em;
      border-radius: 1.25em;
      background-color: #fbf9f9;
    }
    .empty-list-image {
      width: 50%;
    }
    .stake-bottom {
      margin-top: 1em;
    }
    .stake-btn {
      display: block-inline;
      border-radius: 0.625em;
      width: 100%;
      margin: 0 0.75em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .stake-bottom {
      padding-bottom: 1em;
    }
    .score-tip-step {
      opacity: 0.5;
      font-size: 1em;
    }

    .nft-stake-description-text {
      color: #6c5461;
      font-size: 0.825em;
      line-height: 1.2;
      opacity: 0.5;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    .nft-stake-description-text-rule {
      color: #6c5461;
      font-size: 0.825em;
      line-height: 1.2;
      text-decoration: underline;
      margin-bottom: 2em;
    }
    .nft-card-detail-wrap {
      width: 100%;
      background: #fff;
      padding-top: 1.25em;
      border-radius: 0.825em;
      padding-bottom: 1.25em;

      .nft-avator-wrap {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2 solid #6c5461;
        border-radius: 0.825em;
        color: #6c5461;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        border: 2px solid;
        margin: 0 auto;
        img {
          width: 90%;
          margin-bottom: 0.825em;
        }
      }

      .staking-text-section {
        color: #6c5461;
        font-size: 1em;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 1em;

        .staking-title-common {
          opacity: 0.5;
          text-align: left;
        }
        .staking-title-common-value {
          text-align: right;
        }
        .staking-title-common-value-submit {
          color: #dc1c4b;
          text-align: right;
        }
      }
    }
    .staking-comfirm-btn-wrap {
      padding: 1em;
      .staking-confirm-btn {
        width: 100%;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        background-color: #6c5461;
        border-radius: 0.825em;
        color: #fff;
        text-align: center;

        &.disabled {
          background-color: rgba(177, 180, 193, 0.5);
          color: #fff;
        }
      }
    }
  }

  @media (min-width: 577px) {
    color: #6c5461;
    width: 65%;
    overflow: scroll;
    .nft-stake-description-wrap {
      margin-top: 2.5em;
      margin-bottom: 2.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nft-stake-description-text {
        opacity: 0.5;
      }
    }
    .nft-card-detail-wrap {
      display: flex;
      background-color: #fbf9f9;
      justify-content: space-between;
      align-items: center;
      padding: 1em 2em;
      border-radius: 0.825em;
      .nft-avator-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 35%;
        > img {
          width: 100%;
          margin-bottom: 0.825em;
          border-radius: 0.825em;
        }
      }

      .staking-text-section {
        color: #6c5461;
        font-size: 1em;
        padding-left: 1em;
        .staking-title-wrap {
          display: flex;
          justify-content: space-between;
          padding: 0.75em 0;
        }

        .staking-title-common {
          opacity: 0.5;
          text-align: left;
        }
        .staking-title-common-value {
          text-align: right;
        }
        .staking-title-common-value-submit {
          color: #dc1c4b;
          text-align: right;
        }
      }

      .staking-text-section {
        flex: 1;
      }
    }

    .staking-comfirm-btn-wrap {
      width: 30%;
      margin: 2em auto;
      .staking-confirm-btn {
        padding: 1em;
        border-radius: 0.825em;
        background-color: #6c5461;
        color: #fff;
        display: flex;
        align-content: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .nft-list-outer-wrap {
      max-height: 25em;
      overflow-y: scroll;
    }
    .btn-group {
      width: 56%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > button {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .nft-list-outer-wrap {
      background-color: #fbf9f9;
      border-radius: 0.825em;
      padding: 1em;
    }
  }
`

export default ModalContent
