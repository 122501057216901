import { ArtStyled } from './style'
import roadBookPng from './assets/road_book.png'
import arrowPng from './assets/arrow.png'


export const Art = () => {
  return (
    <ArtStyled id='ROADMAP'>
      <div className='art_wrapper'>
        <img className='roadmap' src={roadBookPng} alt=''/>

        <img className='arrow' src={arrowPng} alt="" />
      </div>
    </ArtStyled>
  )
}
