import { AboutStyled } from './style'
import aboutTemplePng from './assets/about_temple.png'
// import rightBookPng from './assets/right_book.png'
import magicBookGif from './assets/magic_book.gif'

export const About = () => {

  return (
    <AboutStyled id='ABOUT'>
      <div className='about_wrapper'>
        <div className="about_left">
          <img src={aboutTemplePng} alt="" />
        </div>

        <div className="about_right">
          <div className="about_contont">
            <h2>WANGHAI</h2>
            <h3>Village </h3>
            {/* <p className='about_subTitle'>GENERAL #1</p> */}
            <p className='about_text'>Welcome to Wanghai Village, where we will start the journey of belonging to the Wanghai Village community. This place belongs to all the users who have grown up with us. We will pass on the story of Wanghai Village here, and hope you will meet friends from different fields in the community to guard the village together!</p>
            <br />
            <p className='about_text'>We are a story-oriented NFT series with anime style art. It is an anime collection of 1000 Genesis Wanghai Village Guardians.
              We will use NFT as a medium to build a stylish, loving, and mutually helpful community, and a brand in web3.0.  We are not only provide NFT products,
              but also hope to provide spiritual and material help to our friends who have dreams. With the community as the center of the circle and the dream as the driving force,
              we encourage everyone to take the first step. We believe that the NFT space needs to be a place where people can engage, enjoy each other's company, and really have fun.
            </p>
          </div>
          <img src={magicBookGif} alt="" />
        </div>
      </div>
    </AboutStyled>
  )
}