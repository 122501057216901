import React from 'react'
import { HomeStyled } from './style'
import homeBgMp4 from './assets/home_bg.mp4'
// import homeBgGif from './assets/home_bg.gif'
// import mintPng from './assets/mint.png'
import { Element } from 'react-scroll'
// import { useHistory } from 'react-router-dom'
import homeBgPng from './assets/home_bg.jpg'

interface IHome {
    handleLoadedState?: (args?: any) => void;
}

export const Home: React.FC<IHome> = (props) => {
    const { handleLoadedState } = props;
    // const history = useHistory()


    const observePlayState = (e: any) => {
        handleLoadedState && handleLoadedState(true);
    }

    const observeSuspend = (e: any) => {
    }


    return (
        <HomeStyled>
            <Element name={'HOME'} id="HOME">
                <div className='white_box'></div>

                <video className='bg' id='videoPlay' poster={homeBgPng} autoPlay={true} loop={true} muted onSuspend={observeSuspend} onCanPlayThrough={observePlayState}>
                    <source src={homeBgMp4} type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                    您的浏览器不支持 video 标签。
                </video>
                {/* <Player
                    playsInline
                    autoPlay={true}
                    src={homeBgMp4}>
                    <Shortcut clickable={false}
                        dblclickable={false} />
                    <BigPlayButton position="center" />
                    <ControlBar className="controlBar" autoHide={true}
                        disableDefaultControls={true}
                    />
                </Player> */}
                {/* <img className='mint_btn' src={mintPng} alt="" onClick={() => history.push('/mint')} /> */}
            </Element>
        </HomeStyled>
    )
}
