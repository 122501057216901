// import styled from 'styled-components'
import { styled } from '@mui/material'

export const HeaderStyled = styled('div')`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5em;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5);
  min-width: 1000px;

  .context_wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    image.logo {
      width: 6em;
    }

    ul {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        /* margin-left: 100px; */
        font-family: 'Coco';
        cursor: pointer;
        font-weight: 400;
        font-size: 1.125em;

        &:hover {
          transform: scale(1.05);
          opacity: 0.8;
          transition: all 0.3s;
        }

        /* &:first-child{
                    margin-left: 0;
                } */
        &.disable {
          color: #cccccc;
        }

        &.active {
          color: #4a6b8f;
          height: 100%;
          position: relative;
          height: 30px;
          display: flex;
          align-items: center;

          &::after {
            content: '';
            width: 60px;
            height: 5px;
            background: linear-gradient(
              90deg,
              #4a6b8f 0%,
              rgba(74, 107, 143, 0) 100%
            );
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }

    .right_box {
      display: flex;
      align-items: center;
      .connect_wallet {
        width: 17em;
        box-sizing: border-box;
      }

      .social_box {
        display: flex;
        align-items: center;
        margin-left: 4em;

        img {
          width: 1.5em;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
            transition: all 0.3s;
          }

          &:active {
            transform: scale(1);
          }
        }
      }
    }
  }
  .context_wrapper_xs {
    display: none;
  }

  @media (max-width: 576px) {
    min-width: 576px;
    position: fixed;
    .context_wrapper {
      display: none;
    }

    .context_wrapper_xs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em;
      flex-direction: row;
      width: 100%;
      box-sizing: border-box;
    }
    .context_menu_drawer {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      background-color: #ffffff;
      .menu_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1em;
      }
      .xs_menu {
        padding-left: 2em;
        li {
          margin-top: 2em;
          color: rgba(51, 51, 51, 1);
        }
      }
      .xs_menu_bottom {
        position: fixed;
        bottom: 0;
        padding-left: 2em;
      }
      .xs_menu_social_box {
        display: flex;
        align-items: center;
        width: 70%;
        justify-content: space-between;
        margin-top: 2em;
        margin-bottom: 3em;

        img {
          width: 1.5em;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
            transition: all 0.3s;
          }

          &:active {
            transform: scale(1);
          }
        }
      }
    }
  }
`
